import "./metasearch.scss";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DefaultLayout from "../../layout/defaultLayout";
import {
  Box,
  Button,
  Chip,
  Menu,
  Snackbar,
  Alert,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import {
  platformOptions,
  platformDesignationOptions,
} from "mock/filterOptions";
import CustomCheckbox from "components/Checkbox";
import ChartContainer from "features/metasearch/chartContainer";
import TableContainer from "features/metasearch/TableContainer";
import EditBidContainer from "features/metasearch/editBidContainer";
import { DatePicker } from "components/DatePicker/index";
import { useDispatch, useSelector } from "react-redux";
import qs from 'query-string';
import {
  updatePlatform,
  updateSegmentList,
  updateSelectedSegments,
} from "store/slices/metaSearch/metaSearch";
import {
  updateLevel,
  updateHotelId,
  updateGroupId,
} from "store/slices/account/accountSlice";
import HotelsFilterMenu from "components/hotelFilterMenu";
import {
  updateEditBidHotels,
  updateEditBidMarkets,
    setOpenEditBidding,
} from "store/slices/editBid/editBid";
import PageTitles from "../../components/PageTitles";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { getSelectedSegments } from "utils/functions/getSelectedSegment";

const MetaSearch = () => {
  const navigate = useNavigate()
  const {pathname, search} = useLocation()
  const platform = useSelector((state) => state?.metaSearch?.platform);
    const {
        account_id = "",
        "is_account_writable": isAccountWritable,
    } = useSelector((state) => state?.account?.account || {});
  const searchParams = qs.parse(window.location.search)
  const {hotel_id = [], level, group_id = [], hotelList = [], groupList = [], can_use_groups: showGroups } = useSelector(state =>state?.account)
  const hotelButtonRef = useRef(null);
  const platformButtonRef = useRef(null);
  const [openPlatformModal, setOpenPlatformModal] = useState(false);
  const [openHotelModal, setOpenHotelModal] = useState(false);
  const [selectedHotelOrGroupType, setSelectedHotelOrGroupType] =
    useState("hotels");
  const [platformFilters, setPlatformFilters] = useState([]);
  const scrollContainerRef = useRef(null);
  const [isChartCollapsed, setIsChartCollapsed] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const dispatch = useDispatch();
  const openEditBidding = useSelector((state) => state.editBid.openEditBidding);
  const [,setCurrentAccountId] = useState(account_id);

    const noBiddingOption = useMemo(() => {
        return (platform.length === 1 && platform[0]?.id === "googleHotelAdsFBL");
    }, [platform]);

    // Function for setting filters from query params
    const setQueryFilters = useCallback(() => {
      const platforms = searchParams?.["platform"];
      const hotelIds = searchParams?.["hotelid"];
      const groupIds = searchParams?.["groupid"];
      if(platforms){
        if(typeof platforms === 'string'){
        dispatch(updatePlatform([[...platformOptions,...platformDesignationOptions].find(p => p.channel_id === platforms)]))
        } else {
        dispatch(updatePlatform([...platformOptions,...platformDesignationOptions].filter(p => platforms.includes(p.channel_id))))
        }
      } else {
        dispatch(updatePlatform([...platformOptions,...platformDesignationOptions]));
      }
      if(hotelIds){
        dispatch(updateLevel("HOTEL"))
        dispatch(updateGroupId([]))
        if(typeof hotelIds === "string"){
          dispatch(updateHotelId([Number(hotelIds)]))
        }else {
          dispatch(updateHotelId(hotelIds.map(h => Number(h))))
        }
      }

      if(groupIds){
        dispatch(updateHotelId([]))
        dispatch(updateLevel("GROUP"))
        if(typeof groupIds === "string"){
          dispatch(updateGroupId([Number(groupIds)]))
        }else {
          dispatch(updateGroupId(groupIds.map(h => Number(h))))
        }
      }
    },[dispatch,hotelList, level, searchParams])

  useEffect(() => {
    if(account_id && hotelList.length){
      setCurrentAccountId(prev => {
        if(prev !== account_id){
          setPlatformFilters([]);
          setQueryFilters();
        }
        return account_id;
      })
    }else{
      setQueryFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account_id, dispatch, hotelList, search]);

  useEffect(() => {
    return () => {
      dispatch(updatePlatform([...platformOptions,...platformDesignationOptions]));
      dispatch(updateSegmentList(["CHANNEL"]))
      dispatch(updateLevel("HOTEL"))
    }
  },[dispatch])

  useEffect(() => {
    if(!level){
      dispatch(
        updateLevel(selectedHotelOrGroupType === "hotels" ? "HOTEL" : "GROUP")
      );
    }
    setQueryFilters();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch,search,level]);

  useEffect(() => {
    if(Object.keys(searchParams).length && (searchParams?.hotelid || searchParams?.groupid)){
      const searchLevel = searchParams?.groupid ? "GROUP": "HOTEL";
      const hotels = typeof searchParams?.hotelid === "string" ? [Number(searchParams?.hotelid)] : searchParams?.hotelid;
      const groups = typeof searchParams?.groupid === "string" ? [Number(searchParams?.groupid)] : searchParams?.groupid;
      dispatch(updateSegmentList(getSelectedSegments(platform, searchLevel === "HOTEL" ? hotels || [] : groups || [], searchLevel)))
    } else {
      dispatch(updateSegmentList(getSelectedSegments(platform, level === "HOTEL" ? hotel_id : group_id, level)))
    }
    dispatch(updateSelectedSegments(null))
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[platform,level,hotel_id,group_id,dispatch,search])

  const FilterMenu = ({
    open,
    options,
    onClose,
    filterType,
    onApply,
    anchorEl,
    optionsDesignation,
  }) => {
    //Selectors
    const [selected, setSelected] = useState(options);
    const [selectedDesignation, setSelectedDesignation] =
      useState(optionsDesignation);

    const isSelectAllChecked = useMemo(() => {
      const selectedCount = selected.filter((item) => item.isSelected).length;

      return selectedCount === options.length;
    }, [options.length, selected]);
    const isDesignatedAllChecked = useMemo(() => {
      const selectedCount = selectedDesignation.filter(
        (item) => item.isSelected
      ).length;

      return selectedCount === optionsDesignation.length;
    }, [optionsDesignation, selectedDesignation]);
    const toggleSelectAll = (type) => {
      if (type === "metasearch") {
        const selectedCount = selected.filter((item) => item.isSelected).length;
        if (selectedCount === options.length) {
          setSelected(
            selected.map((item) => {
              return { ...item, isSelected: false };
            })
          );
        } else {
          setSelected(
            selected.map((item) => {
              return { ...item, isSelected: true };
            })
          );
        }
      } else {
        const selectedCount = selectedDesignation.filter(
          (item) => item.isSelected
        ).length;
        if (selectedCount === optionsDesignation.length) {
          setSelectedDesignation(
            selectedDesignation.map((item) => {
              return { ...item, isSelected: false };
            })
          );
        } else {
          setSelectedDesignation(
            selectedDesignation.map((item) => {
              return { ...item, isSelected: true };
            })
          );
        }
      }
    };

    return (
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        sx={{
          width: "100%",
          "& .MuiPaper-root": {
            borderRadius: "10px",
          },
        }}
      >
        <div style={{ paddingInline: "10px" }}>
          <div
            style={{
              color: "var(--Grey, #C4C4C4)",
              fontFamily: "Inter",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>{filterType}</span>
            <span onClick={onClose}>
              <CloseOutlined sx={{
                  fontSize: "16px",
                  cursor: "pointer",
                  "&:hover": { color: "black"}
              }} />
            </span>
          </div>
          <div style={{ display: "flex" }}>
            <ul
              style={{
                listStyleType: "none",
                marginBlock: 0,
                padding: 0,
                paddingLeft: "4px",
                overflow: "auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <span>
                  <CustomCheckbox
                    size={"small"}
                    onChange={() => {
                      toggleSelectAll("metasearch");
                    }}
                    checked={isSelectAllChecked}
                    title={"Metasearch"}
                    labelStyles={{
                      color: "#c4c4c4",
                      fontFamily: "Inter",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  />
                </span>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  gap: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    marginRight: "0px",
                    marginLeft: "5px",
                  }}
                >
                  {selected?.map((item) => (
                    <li
                      key={item.id}
                      style={{
                        borderLeft: "1px solid #CADEDD",
                        paddingLeft: "10px",

                        paddingBlock: "5px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <span
                            style={{
                              flex: "0 0 50%",
                              textAlign: "right",
                              marginRight: "16px",
                            }}
                          >
                            <CustomCheckbox
                              size={"small"}
                              checked={item.isSelected}
                              title={item.label}
                              labelStyles={{
                                color: "#000",
                                fontFamily: "Inter",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "normal",
                              }}
                              onChange={() => {
                                setSelected(
                                  selected.map((i) => ({
                                    ...i,
                                    isSelected:
                                      i.id === item.id
                                        ? !i.isSelected
                                        : i.isSelected,
                                  }))
                                );
                              }}
                            />
                          </span>
                        </Box>
                      </div>
                    </li>
                  ))}
                </Box>
              </Box>
            </ul>

            <ul>
              <Box sx={{ flex: 1, display: "flex" }}>
                <CustomCheckbox
                  size={"small"}
                  title={"Destination Ads"}
                  onChange={() => {
                    toggleSelectAll("DesignationAds");
                  }}
                  checked={isDesignatedAllChecked}
                  labelStyles={{
                    color: "gray",
                    fontFamily: "Inter",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "5px",
                }}
              >
                {selectedDesignation?.map((item) => (
                  <li
                    key={item.id}
                    style={{
                      borderLeft: "1px solid #CADEDD",
                      paddingLeft: "10px",
                      paddingBlock: "5px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <span
                          style={{
                            flex: "0 0 50%",
                            textAlign: "right",
                          }}
                        >
                          <CustomCheckbox
                            size={"small"}
                            checked={item.isSelected}
                            title={item.label}
                            sx={{
                              color: "#000",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                            }}
                            onChange={() => {
                              setSelectedDesignation(
                                selectedDesignation.map((i) => ({
                                  ...i,
                                  isSelected:
                                    i.id === item.id
                                      ? !i.isSelected
                                      : i.isSelected,
                                }))
                              );
                            }}
                          />
                        </span>
                      </Box>
                    </div>
                  </li>
                ))}
              </Box>
            </ul>
          </div>
        </div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "14px",
            paddingBlock: "8px",
          }}
        >
          <span
            style={{
              flex: "0 0 50%",

              marginRight: "16px",

              fontSize: "10px",
            }}
            className="sublabel"
            onClick={() => {
              setSelected(
                selected.map((item) => {
                  return { ...item, isSelected: false };
                })
              );
              setSelectedDesignation(
                selectedDesignation.map((item) => {
                  return { ...item, isSelected: false };
                })
              );
            }}
          >
            Unselect All
          </span>
          <Button
            variant="outlined"
            sx={{
              color: "black",
              backgroundColor: "#61D5A9",
              borderRadius: "20px",
              fontSize: "10px",
              width: "100px",
              paddingInline: "8px",

              fontFamily: "inter",
              height: "20px",
            }}
            onClick={() => {
              onApply([
                ...selected?.filter((item) => item.isSelected),
                ...selectedDesignation.filter((item) => item.isSelected),
              ]);
              onClose();
            }}
          >
            Apply
          </Button>
        </Box>
      </Menu>
    );
  };

  return (
    <div className="metasearch">
      <DefaultLayout page="metaSearch">
          <Box
              sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginRight: "18px",
              }}
          >
          <PageTitles pageTitle="Metasearch" pageSubTitle="" />
          <DatePicker />
        </Box>
        <Box
          sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px",
              paddingInline: "16px",
          }}
        >
          <div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#000000",
                  fontWeight: "400",
                  marginTop: "4px",
                }}
              >
                <div
                  onClick={() => {
                    setOpenHotelModal(true);
                  }}
                  ref={hotelButtonRef}
                >
                  <Chip
                    label={
                      level === "HOTEL" ? hotel_id.length === 0
                        ? "Hotels"
                        : hotel_id.length === hotelList.length
                          ? "All Hotels"
                        : hotel_id.length === 1
                        ? hotelList?.find(
                            (hotel) =>
                              hotel.hotel_id === hotel_id?.[0]
                          )?.hotel_name
                        : `${hotel_id.length} ${
                             "hotels"
                          } selected`: group_id.length === 0 ? "Groups" : group_id.length === groupList.length ? "All Groups" : group_id.length === 1
                          ? groupList?.find(
                              (group) =>
                                group.id === group_id?.[0]
                            )?.name
                          : `${group_id.length} ${
                               "groups"
                            } selected`
                    }
                    sx={{
                      backgroundColor: "inherit",
                      fontFamily: "inter",
                      fontSize: "12px",
                      fontWeight: "400",
                      marginRight: "4px",
                      height: "26px",
                      minWidth:'120px',
                      border:"1px solid #BAB7B7",
                      ":hover": {
                        cursor: "pointer",
                        border: "1px solid #000000",
                      },
                    }}
                  />
                </div>

                <div
                  onClick={() => {
                    setOpenPlatformModal(true);
                  }}
                  ref={platformButtonRef}
                >
                  <Chip
                    label={
                      platform.length === 0
                        ? "Platforms"
                        : platform.length === 1
                        ? platform?.[0]?.label
                        : platform.length === [...platformOptions,...platformDesignationOptions].length
                        ? "All Platforms"
                        : `${platform.length} platforms selected`
                    }
                    sx={{
                      backgroundColor:"inherit",
                      fontFamily: "inter",
                      fontSize: "12px",
                      fontWeight: "400",
                      marginRight: "4px",
                      height: "26px",
                      minWidth:'120px',
                      border:"1px solid #BAB7B7",
                      ":hover": {
                          cursor: "pointer",
                          border: "1px solid #000000",
                      },
                    }}
                  />
                </div>
                {openPlatformModal && (
                  <FilterMenu
                    anchorEl={platformButtonRef.current}
                    open={openPlatformModal}
                    options={platformOptions?.map((po) =>
                      platform?.find((pf) => pf.id === po.id)
                        ? { ...po, isSelected: true }
                        : { ...po, isSelected: false }
                    )}
                    optionsDesignation={platformDesignationOptions?.map((po) =>
                      platform?.find((pf) => pf.id === po.id)
                        ? { ...po, isSelected: true }
                        : { ...po, isSelected: false }
                    )}
                    filterType={"Platform"}
                    selectAllTitle={"Platforms"}
                    onApply={(selected) => {
                      dispatch(updateSegmentList(getSelectedSegments(selected, level === "HOTEL" ? hotel_id : group_id, level)))
                      dispatch(updateSelectedSegments(null))
                      setPlatformFilters(selected);
                      dispatch(setOpenEditBidding(false))
                      dispatch(updatePlatform(selected));
                      dispatch(updateEditBidHotels([]));
                      dispatch(updateEditBidMarkets([]));
                      navigate(`${pathname}?${createSearchParams({...searchParams,platform: selected?.map(p => p.channel_id)})}`)
                    }}
                    onClose={() => {
                      setOpenPlatformModal(false);
                    }}
                  />
                )}
                {openHotelModal && (
                  <HotelsFilterMenu
                    allHotels={hotelList?.map((ho) =>
                      hotel_id.find((hf) => hf === ho.hotel_id)
                        ? { ...ho, isSelected: true }
                        : { ...ho, isSelected: false }
                    )}
                    showGroups={showGroups}
                    anchorEl={hotelButtonRef.current}
                    open={openHotelModal}
                    allGroups={groupList?.map((ho) =>
                      group_id.find((hf) => hf === ho.id)
                        ? { ...ho, isSelected: true }
                        : { ...ho, isSelected: false }
                    )}
                    isSelectAllRequired
                    onApply={(selected) => {
                      dispatch(
                        updateLevel(selectedHotelOrGroupType === "hotels" ? "HOTEL" : "GROUP")
                      );
                      dispatch(updateSegmentList(getSelectedSegments(platform, selected, selectedHotelOrGroupType === "hotels" ? "HOTEL" : "GROUP")))
                      dispatch(updateSelectedSegments(null))
                      if (selectedHotelOrGroupType === "hotels") {
                        dispatch(
                          updateHotelId(
                            selected.map((item) => item?.["hotel_id"])
                          )
                        );
                        dispatch(updateGroupId([]))
                        delete searchParams?.groupid
                        navigate(`${pathname}?${createSearchParams({...searchParams, hotelid: selected?.map(p => p.hotel_id)})}`)
                      } else {
                        dispatch(
                          updateGroupId(selected.map((item) => item?.["id"]))
                        );
                        dispatch(updateHotelId([]))
                        delete searchParams?.hotelid
                        navigate(`${pathname}?${createSearchParams({...searchParams, groupid: selected?.map(p => p.id)})}`)
                      }
                    }}
                    onClose={() => {
                      setOpenHotelModal(false);
                    }}
                    setSelectedHotelOrGroupType={(type) => {
                      setSelectedHotelOrGroupType(type);
                    }}
                    selectedHotelOrGroupType={selectedHotelOrGroupType}
                  />
                )}
              </div>
            </div>
          </div>
        </Box>
        <Box ref={scrollContainerRef}>
          <div
            style={{
              height: "228px",
              display: isChartCollapsed || openEditBidding ? "none" : "block",
            }}
          >
            <ChartContainer backgroundColor="transparent" hotelList={hotelList}/>
          </div>
          {openEditBidding && (
            <div
              style={{
                height: "228px",
                display: openEditBidding ? "block" : "none",
              }}
            >
              <EditBidContainer />
            </div>
          )}
          <TableContainer
            hotelList={hotelList}
            selectedPlatform={platformFilters}
            isChartCollapsed={isChartCollapsed}
            setIsChartCollapsed={setIsChartCollapsed}
            noBiddingOption={noBiddingOption}
            isAccountWritable={isAccountWritable}
            onEditClick={() => {
              if (platform?.length > 1) {
                setToastMessage("Please select single platform");
                setToastType("error");
                setShowToast(true);
                return;
              }
              dispatch(setOpenEditBidding(!openEditBidding));
            }}
          />
        </Box>
      </DefaultLayout>
      <Snackbar
        // anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showToast}
        autoHideDuration={3000}
        onClose={() => {
          setShowToast(false);
        }}
      >
        <Alert severity={toastType} variant="filled" sx={{ width: "100%" }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default MetaSearch;
