import React, {useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from "@mui/material";
import "./CampaignAccordion.scss";
import CustomButtonWithIcon from "components/CustomButtonWithIcon/Buttons";
import CustomButtonWithIconComponent from "components/CustomButtonWithIcon/Buttons";

const CampaignAccordion = ({
                               title,
                               children,
                               expanded,
                               onChange,
                               onNext = false,
                               disabled,
                               visited,
                               isCreateButton = false,
                               isSaveButton = false,
                               onSave = false,
                               isCancelButton = false,
                               expandIcon,
                               onCancel = () => {},
                               handleGetSuggestion = false
                           }) => {
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleConfirmGetSuggestion = async () => {
        setDialogOpen(false);
        await handleGetSuggestion();
    };

    return (
        <>
            <Accordion
                className="search-ads-accordion"
                expanded={expanded}
                defaultExpanded
                onChange={!disabled ? onChange : null}
                sx={{
                    pointerEvents: disabled ? "none" : "auto",
                    opacity: disabled ? 0.6 : 1,
                }}
            >
                <AccordionSummary
                    className="accordion-summary"
                    expandIcon={expandIcon ?? null}
                    sx={{
                        height: "42px",
                        maxHeight: "42px",
                        minHeight: "42px",
                        backgroundColor: visited && !expanded ? "#61D5A9" : "inherit",
                        borderBottom: expanded ? "1px solid #61D5A9" : "none",
                        cursor: disabled ? "default" : "pointer",
                        color: visited && !expanded ? "black" : "inherit",
                        "&.Mui-expanded": {
                            height: "42px",
                            maxHeight: "42px",
                            minHeight: "42px",
                        },
                    }}
                >
                    <Typography>{title}</Typography>
                    {expanded && handleGetSuggestion && (
                        <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={handleDialogOpen}
                            sx={{
                                marginLeft: 'auto',
                                marginRight: '15px',
                            }}
                        >
                            Get Keywords Suggestions
                        </Button>
                    )}
                </AccordionSummary>
                <AccordionDetails
                    className="accordion-details"
                    sx={{
                        backgroundColor: "#FAFAFA",
                    }}
                >
                    {children}
                    {
                        <Box
                            display="flex"
                            gap="15px"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            {onNext && (
                                <CustomButtonWithIcon
                                    color="#b0acac"
                                    hoverColor="#12794F"
                                    titleColor="#373F41"
                                    hoverTitleColor={"white"}
                                    width={"120px"}
                                    height={"24px"}
                                    borderRadius="20px"
                                    textStyle={{
                                        fontFamily: "Inter",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        lineHeight: "14.52px",
                                        textAlign: "center",
                                    }}
                                    onClick={onNext}
                                >
                                    {isCreateButton ? "Create" : "Next"}
                                </CustomButtonWithIcon>
                            )}
                            {isSaveButton && (
                                <CustomButtonWithIcon
                                    color="#b0acac"
                                    hoverColor="#12794F"
                                    titleColor="#373F41"
                                    hoverTitleColor={"white"}
                                    width={"120px"}
                                    height={"24px"}
                                    borderRadius="20px"
                                    textStyle={{
                                        fontFamily: "Inter",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        lineHeight: "14.52px",
                                        textAlign: "center",
                                    }}
                                    onClick={onSave}
                                >
                                    Save
                                </CustomButtonWithIcon>
                            )}
                            {isCancelButton && (
                                <CustomButtonWithIcon
                                    color="#b0acac"
                                    hoverColor="#12794F"
                                    titleColor="#373F41"
                                    hoverTitleColor={"white"}
                                    width={"120px"}
                                    height={"24px"}
                                    borderRadius="20px"
                                    textStyle={{
                                        fontFamily: "Inter",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        lineHeight: "14.52px",
                                        textAlign: "center",
                                    }}
                                    onClick={onCancel}
                                >
                                    Cancel
                                </CustomButtonWithIcon>
                            )}
                        </Box>
                    }
                </AccordionDetails>
            </Accordion>

            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="keywords-dialog-title"
                aria-describedby="keywords-dialog-description"
            >
                <DialogTitle id="keywords-dialog-title">
                    <span style={{ fontSize: "12px", fontFamily: "Inter" }}>CONFIRM OVERWRITE</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="keywords-dialog-description">
                    <span style={{ fontSize: "12px", fontFamily: "Inter" }}>
                        Are you sure you want to overwrite all the data in the "Keywords" section? This action cannot be undone.
                    </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CustomButtonWithIconComponent
                        title="Cancel"
                        onClick={handleDialogClose}
                        textStyle={{ fontSize: '14px', fontWeight: 'bold' }}
                        color="grey"
                    >
                        Cancel
                    </CustomButtonWithIconComponent>
                    <CustomButtonWithIconComponent
                        title="Confirm"
                        onClick={handleConfirmGetSuggestion}
                        textStyle={{ fontSize: '14px', fontWeight: 'bold' }}
                        color="#61D5A9"
                    >
                        Confirm
                    </CustomButtonWithIconComponent>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CampaignAccordion;
