import React from "react";
import { Typography, Box, IconButton, Grid, InputAdornment } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";

const Section = ({
                     title,
                     items,
                     addItem = false,
                     handleInputChange,
                     handleRemove = false,
                     maxLengthInput = null,
                     onBlur,
                     status = {},
                     errorMessage
                 }) => {
    return (
        <Grid item xs={12} spacing={2}>
            <Box className={title.toLowerCase().replace(/ /g, "-")}>
                <Box display="flex" alignItems="center" justifyContent="space-between" className="header-box" paddingBottom="1px">
                    <Box display="flex" alignItems="center" gap={1}>
                        <Typography variant="subtitle1" className="sectionTitle">
                            {title}
                        </Typography>
                        {typeof errorMessage === "string" && (
                            <Typography className="text-error-section">
                                {errorMessage}
                            </Typography>
                        )}
                    </Box>
                    <Box display="flex" alignItems="center">
                        {addItem && (
                            <IconButton onClick={addItem} color="primary" className="add-button">
                                <AddIcon />
                            </IconButton>
                        )}
                    </Box>
                </Box>

                <Box className="content-box" mt={2}>
                    {items.map((item, index) => {
                        const currentError = Array.isArray(errorMessage) ? errorMessage?.[`index-${index}`] : null;
                        const currentStatus = status?.[item.id];

                        return (
                            <Box key={item.id} display="flex" alignItems="center" gap={1} className="input-container">
                                <CustomOutlinedInput
                                    placeholder={`${title} ${index + 1}`}
                                    value={item.text}
                                    onChange={(e) => handleInputChange(item.id, 'text', e.target.value,index)}
                                    onBlur={() => onBlur(item.id, item.text)}
                                    className="no-border-focus"
                                    fullWidth
                                    minWidth="225px"
                                    inputProps={{ maxLength: maxLengthInput || undefined }}
                                    InputProps={{
                                        endAdornment: maxLengthInput && item.text && (
                                            <InputAdornment position="end">
                                                {`${item.text.length}/${maxLengthInput}`}
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={Boolean(currentError) || (currentStatus && !currentStatus.success)}
                                    helperText={
                                        currentError ||
                                        currentStatus?.error ||
                                        `${title} ${index + 1}`
                                    }
                                    success={currentStatus && currentStatus.success === true}
                                />
                                {handleRemove && (
                                    <IconButton
                                        onClick={() => handleRemove(item.id)}
                                        color="primary"
                                        className="remove-button"
                                        style={{
                                            visibility: item.irremovable ? 'hidden' : 'initial',
                                            marginTop: '-28px'
                                        }}
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                )}
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Grid>
    );
};

export default Section;
