import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openItems: {},
  activeSection: "",
  openSharedBudgetPanel : false
};
export const sidebarSlice = createSlice({
  name: "sidebarSlice",
  initialState: initialState,
  reducers: {
    updateOpenItems: (state, action) => {
      state.openItems = action.payload;
    },
    setActiveSection: (state, action) => {
      state.activeSection = action.payload;
    },
    setOpenSharedBudgetPanel: (state,action) => {
      state.openSharedBudgetPanel = action.payload;
    }
  },
});

export const { updateOpenItems, setActiveSection, setOpenSharedBudgetPanel } = sidebarSlice.actions;
export default sidebarSlice.reducer;
