import React, {useState} from "react";
import CampaignAccordion from "../googleSearchAdsFormComponents/Campaign/CampaignAccordion";
import KeywordsAccordion from "../googleSearchAdsFormComponents/Keywords/KeywordsAccordion";
import AdsCreativeAccordion from "../googleSearchAdsFormComponents/AdsCreative/AdsCreativeAccordion";
import CampaignSettingsAccordion from "../googleSearchAdsFormComponents/CampaignSettings/CampaignSettingsAccordion";
import {validateCampaignSettings, validateKeywords,} from "../googleSearchAdsFormComponents/Utils/validators";
import {useSelector} from "react-redux";
import {Alert, Snackbar} from "@mui/material";
import {APP_GOOGLE_SEM_API_ENDPOINT} from "constants/api_urls";
import axios from "axios";
import {ExpandMore} from "@mui/icons-material";
import {enqueueSnackbar} from "notistack";
import {getUserToken} from "../../../utils/functions/userToken";

const GoogleSearchAdsCampaignEditor = ({
    expandedPanel,
    setExpandedPanel,
    selectedObjective,
    setSelectedObjective,
    visitedPanels,
    handleNext,
    selectedCountries,
    setSelectedCountries,
    selectedLanguage,
    setSelectedLanguage,
    targetValue,
    setTargetValue,
    biddingStrategy,
    setBiddingStrategy,
    campaignName,
    setCampaignName,
    targetCpa,
    setTargetCpa,
    targetRoas,
    setTargetRoas,
    maxCpcLimit,
    setMaxCpcLimit,
    impressionShare,
    setImpressionShare,
    adLocation,
    setAdLocation,
    monthlyBudget,
    setMonthlyBudget,
    keywords,
    setKeywords,
    negativeKeywords,
    setNegativeKeywords,
    headlines,
    setHeadlines,
    descriptions,
    setDescriptions,
    siteLinks,
    setSiteLinks,
    callOuts,
    setCallOuts,
    structuredSnippets,
    setStructuredSnippets,
    finalURL,
    setFinalURL,
    businessName,
    setBusinessName,
    calls,
    setCalls,
    displayPaths,
    setDisplayPaths,
    dailyBudgetCap,
    setDailyBudgetCap,
    onClose,
    currencySymbol,
    campaignId
}) => {
  const [errors, setErrors] = useState({});

  const token = getUserToken();

  const { campaign = [] } = useSelector((state) => state?.searchAds);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [toastType, setToastType] = useState("error");

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleNextWithValidation = (currentPanel) => {
    let validationResults = {};

    if (currentPanel === "settings") {
      validationResults = validateCampaignSettings({
        campaignName,
        biddingStrategy,
        targetCpa,
        targetRoas,
        maxCpcLimit,
        impressionShare,
        adLocation,
        targetValue,
      });
    } else if (currentPanel === "keywords") {
      validationResults = validateKeywords({ keywords, negativeKeywords });
    }

    setErrors(validationResults);
    handleNext(currentPanel);
  };

  const saveSettings = () => {
    //    TODO SAVE SETTINGS
    const bidValues = {
      MAXIMIZE_CONVERSIONS: targetCpa,
      MAXIMIZE_CONVERSION_VALUE: targetRoas,
      MAXIMIZE_CLICKS: maxCpcLimit,
      MANUAL_CPC: maxCpcLimit,
      MANUAL_CPC_ENHANCED: maxCpcLimit,
      TARGET_IMPRESSION_SHARE: impressionShare,
    };
    const payload = {
      campaign_id: campaignId,
      campaign_settings: {
        campaign_type: selectedObjective,
        campaign_name: campaignName,
        bidding_strategy: {
          bidding_strategy_option: biddingStrategy,
          bidding_value: bidValues?.[biddingStrategy],
          bidding_max_value:
            biddingStrategy === "TARGET_IMPRESSION_SHARE"
              ? maxCpcLimit
              : undefined,
          ad_location: adLocation ?? undefined,
        },
        budget: {
          default_budget: Number(targetValue),
          is_daily_budget: dailyBudgetCap,
          ...Object.keys(monthlyBudget).reduce((acc, curr, index) => {
            acc[`budget_month_${index + 1}`] = Number(monthlyBudget[curr]);
            return acc;
          }, {}),
        },
        campaign_lang: selectedLanguage,
        point_of_sales: selectedCountries,
      },
    };
    axios
      .post(
        `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/edit-campaign/settings/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        enqueueSnackbar({
          message: "Campaign settings updated successfully!",
          variant: "success",
        });
        // setToastType("success");
        // setMessage("Campaign settings updated successfully!");
        // setSnackbarOpen(true);
        setTimeout(() => {
          onClose();
        }, 100);
      })
      .catch((err) => {
        setToastType("error");
        setMessage(err?.message || "Something went wrong!");
        setSnackbarOpen(true);
      });
  };

  const saveKeywords = () => {
    //    TODO SAVE Keywords
    const keywordsPayload = {
      campaign_id: campaignId,
      campaign_keywords: {
        keywords: keywords.map((keyword) => ({
          keywordText: keyword.keyword,
          matchType: keyword?.matchType,
        })),
        keywords_negative: negativeKeywords.map((keyword) => ({
          keywordText: keyword.keyword,
          matchType: keyword?.matchType,
        })),
      },
    };
    axios
      .post(
        `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/edit-campaign/keywords/`,
        keywordsPayload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        enqueueSnackbar({
          message: "Campaign keywords updated successfully!",
          variant: "success",
        });
        setTimeout(() => {
          onClose();
        }, 100);
      })
      .catch((err) => {
        setToastType("error");
        setMessage(err?.message || "Something went wrong!");
        setSnackbarOpen(true);
      });
  };

  const saveAdsCreative = () => {
    //    TODO SAVE AdsCreatie
    const payload = {
      campaign_id: Number(campaignId),
      campaign_ads_creative: {
        final_url: { url: finalURL },
        business_name: {
          assetContent: businessName,
        },
        headline: headlines.map((h) => ({ assetContent: h.text })),
        description: descriptions.map((d) => ({ assetContent: d.text })),
        site_link: siteLinks.map((sitelink) => ({
          siteLinkText: sitelink?.sitelinkText,
          siteLinkDescription1: sitelink?.description1,
          siteLinkDescription2: sitelink?.description2,
          siteLinkFinalUrl: sitelink?.finalURL,
        })),
        callout: callOuts.map((callout) => ({ assetContent: callout?.text })),
        snippet: structuredSnippets.map((snippet) => ({
          assetContent: snippet?.text,
        })),
        phone_call: {
          phoneCountryCode: calls?.[0].countryCode,
          phoneNumber: calls?.[0].phoneNumber,
        },
        display_path: displayPaths.reduce((acc, curr) => {
          acc[curr.id] = curr.text;
          return acc;
        }, {}),
      },
    };

    axios
      .post(
        `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/edit-campaign/ads-creative/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        enqueueSnackbar({
          message: "Campaign updated successfully!",
          variant: "success",
        });
        setTimeout(() => {
          onClose();
        }, 100);
      })
      .catch((err) => {
        setToastType("error");
        setMessage(err?.message || "Something went wrong!");
        setSnackbarOpen(true);
      });
  };
  return (
    <>
      <CampaignAccordion
        expandIcon={<ExpandMore />}
        title="1. Campaign Settings"
        expanded={expandedPanel === "settings"}
        onChange={() => setExpandedPanel("settings")}
        onNext={() => handleNextWithValidation("settings")}
        disabled={!visitedPanels.includes("settings")}
        visited={visitedPanels.includes("settings")}
        isSaveButton={true}
        onSave={saveSettings}
      >
        <CampaignSettingsAccordion
          biddingStrategy={biddingStrategy}
          setBiddingStrategy={setBiddingStrategy}
          campaignName={campaignName}
          setCampaignName={setCampaignName}
          targetCpa={targetCpa}
          setTargetCpa={setTargetCpa}
          targetRoas={targetRoas}
          setTargetRoas={setTargetRoas}
          maxCpcLimit={maxCpcLimit}
          setMaxCpcLimit={setMaxCpcLimit}
          impressionShare={impressionShare}
          setImpressionShare={setImpressionShare}
          adLocation={adLocation}
          setAdLocation={setAdLocation}
          targetValue={targetValue}
          setTargetValue={setTargetValue}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          selectedCountries={selectedCountries}
          setSelectedCountries={setSelectedCountries}
          monthlyBudget={monthlyBudget}
          setMonthlyBudget={setMonthlyBudget}
          errors={errors}
          dailyBudgetCap={dailyBudgetCap}
          setDailyBudgetCap={setDailyBudgetCap}
          isEdit={true}
          currencySymbol={currencySymbol}
          campaignId={campaignId}
        />
      </CampaignAccordion>

      <CampaignAccordion
        expandIcon={<ExpandMore />}
        title="2. Keywords"
        expanded={expandedPanel === "keywords"}
        onChange={() => setExpandedPanel("keywords")}
        onNext={() => handleNextWithValidation("keywords")}
        disabled={!visitedPanels.includes("keywords")}
        visited={visitedPanels.includes("keywords")}
        isSaveButton={true}
        onSave={saveKeywords}
      >
        <KeywordsAccordion
          keywords={keywords}
          setKeywords={setKeywords}
          negativeKeywords={negativeKeywords}
          setNegativeKeywords={setNegativeKeywords}
          errors={errors}
        />
      </CampaignAccordion>

      <CampaignAccordion
        expandIcon={<ExpandMore />}
        title="3. Ads Creative"
        expanded={expandedPanel === "adsCreative"}
        onChange={() => setExpandedPanel("adsCreative")}
        disabled={!visitedPanels.includes("adsCreative")}
        visited={visitedPanels.includes("adsCreative")}
        isSaveButton={true}
        onSave={saveAdsCreative}
      >
        <AdsCreativeAccordion
          headlines={headlines}
          setHeadlines={setHeadlines}
          descriptions={descriptions}
          setDescriptions={setDescriptions}
          siteLinks={siteLinks}
          setSiteLinks={setSiteLinks}
          callOuts={callOuts}
          setCallOuts={setCallOuts}
          structuredSnippets={structuredSnippets}
          setStructuredSnippets={setStructuredSnippets}
          finalURL={finalURL}
          setFinalURL={setFinalURL}
          businessName={businessName}
          setBusinessName={setBusinessName}
          calls={calls}
          setCalls={setCalls}
          displayPaths={displayPaths}
          setDisplayPaths={setDisplayPaths}
          isEdit={true}
        />
      </CampaignAccordion>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={toastType}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GoogleSearchAdsCampaignEditor;
