export const COMMONFORMFIELDS = [
    {
        id: "hotel",
        label: "Hotel",
        type: "multiSelect",
        placeholder: "Select a hotel",
    },

    {
        id: "market",
        label: "Market",
        type: "multiSelect",
        placeholder: "Select a value",
    },
];
export const ghaBiddingOptionsFormFields = [
    {
        id: "bidStrategy",
        label: "Bid Strategy",
        type: "select",
        placeholder: "Select a strategy",
        options: [
            {
                id: "NO_BID",
                label: "No Bid",
                value: "NO_BID",
            },

            {
                id: "CPC_PERCENT",
                label: "MaxCPC-Percentage of Room Price",
                value: "CPC_PERCENT",
            },

            {id: "CPC_FIX", label: "MaxCPC-Fixed bid/night", value: "CPC_FIX"},

            {id: "TROAS", label: "Troas", value: "TROAS"},
        ],
    },
    {
        id: "bidAmount",
        label: "Bid Amount",
        type: "textField",
        placeholder: "Select bid amount",
    },
];
