import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";

const ConfirmImportDialogCampaign = ({ open, onClose, onConfirm, campaignName }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle>Confirm Import</DialogTitle>
            <DialogContent>
                <Typography>
                    Are you sure you want to import images from the campaign <strong>{campaignName}</strong>?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={onConfirm} color="primary" variant="contained">
                    Yes, Import
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmImportDialogCampaign;
