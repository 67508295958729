import React, { useEffect, useMemo, useCallback, useState, useRef } from 'react';
import AlertTable from 'features/alert-center/AlertTable/index';
import {useParams, useSearchParams} from "react-router-dom";
import DefaultLayout from "layout/defaultLayout";
import { Box, IconButton } from "@mui/material";
import '@react-awesome-query-builder/ui/css/styles.css';
import '@react-awesome-query-builder/ui/css/compact_styles.css';
import DmpIcons from "components/DmpIcons/icons";
import { getUserToken } from "../../utils/functions/userToken";


const AlertCenterPage = () => {
    const [searchParams] = useSearchParams();
    const { idaccount } = useParams();
    const jwtToken = getUserToken();
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const [activeRow, setActiveRow] = useState({
        id: null,
        name: null,
        alertPeriod: null,
        segmentList: null,
        additionnalColumns: null,
        recipients: null,
        alertCondition: null,
        alertTypeLabel: null,
        alertSubject: null,
        status: null,
    });

    // Create refs
    const menuRef = useRef(null);
    const settingsIconRefs = useRef({});
    const currentMenuSection = searchParams.get("product");

    useEffect(() => {
        if (activeRow.id) {
        }
    }, [activeRow]);

    const handleCloseMenu = () => {
        setMenuVisible(false);
    };

    const handleIconClick = useCallback((event, alert, rowId) => {
        const rect = event.currentTarget.getBoundingClientRect();
        // Update menu position and active row data
        setMenuPosition({ top: rect.bottom, left: rect.left });
        setActiveRow({
            id: alert.id,
            name: alert.alert_name,
            alertPeriod: alert.alert_period,
            segmentList: alert.segment_list,
            additionnalColumns: alert.additionnal_columns,
            recipients: alert.alert_recipients,
            alertCondition: alert.alert_condition,
            alertType: alert.alert_type,
            alertSubject: alert.alert_subject,
            userEmail: alert.user.username,
            status: alert.status,
        });

        // Store the ref to the clicked settings icon
        settingsIconRefs.current[rowId] = event.currentTarget;

        setMenuVisible(true);
    }, []);

    useEffect(() => {
        document.body.classList.add('alert-center-page');
        const handleClickOutside = (event) => {
            const isClickInsideMenu = menuRef.current && menuRef.current.contains(event.target);
            const isClickOnSettingsIcon = Object.values(settingsIconRefs.current).some(
                (ref) => ref && ref.contains(event.target)
            );

            if (!isClickInsideMenu && !isClickOnSettingsIcon) {
                setMenuVisible(false);
            }
        };

        if (menuVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.body.classList.remove('alert-center-page');
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuVisible]);

    const columns = useMemo(() => [
        { accessorKey: 'alert_name', header: 'Alert Name', sortable: true },
        {
            accessorKey: 'actions',
            header: 'Actions',
            filterFn: 'auto',
            enableColumnFilter: false,
            enableSorting: false,
            enableColumnActions: false,
            sortable: false,
            Cell: ({ row }) => (
                <IconButton
                    onClick={(event) => handleIconClick(event, row, row.id)}
                    ref={(ref) => {
                        if (ref) {
                            settingsIconRefs.current[row.id] = ref;
                        }
                    }}
                >
                    <DmpIcons.ActionsIcons.CogsFillColorIcon className="custom-color-cogs" />
                </IconButton>
            ),
        },
        { accessorKey: 'alert_type_label', header: 'Alert Type', sortable: true },
        { accessorKey: 'platform_label', header: 'Platform', sortable: true },
        { accessorKey: 'created_date', header: 'Date Created', sortable: true },
        { accessorKey: 'status', header: 'Status', sortable: true },
        { accessorKey: 'user.username', header: 'User', sortable: true },
    ], [handleIconClick]);

    return (
        <DefaultLayout page={currentMenuSection}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflow: "auto",
                }}
            >
                <div className="alert-center">
                    <AlertTable
                        idaccount={idaccount}
                        columns={columns}
                        jwtToken={jwtToken}
                        menuVisible={menuVisible}
                        menuPosition={menuPosition}
                        alertData={activeRow}
                        onCloseMenu={handleCloseMenu}
                        menuRef={menuRef}
                    />
                </div>
            </Box>
        </DefaultLayout>
    );
};

export default AlertCenterPage;
