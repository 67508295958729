import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import axios from "axios";
import { APP_GHA_BIDDING_API_ENDPOINT } from "constants/api_urls";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import FormFieldRenderer from "components/FormFieldRenderer";
import MarketsFilterMenu from "components/MarketFilterMenu";
import HotelsFilterMenu from "components/hotelFilterMenu";
import { ArrowDropDown } from "@mui/icons-material";
import TextInput from "components/TextInput";
import { ghaBiddingRulesFormFields } from "./options";
import {
  setOpenEditBidding,
  updateEditBidHotels,
  updateEditBidMarkets,
  updateBiddingLevel,
} from "store/slices/editBid/editBid";
import CustomButtonWithIcon from "../../../../../components/CustomButtonWithIcon/Buttons";
import {validateBiddingRulesPayload} from "../formValidator";
import {getUserToken} from "../../../../../utils/functions/userToken";

const GHABidddingRules = () => {
  const token = getUserToken();
  // Ref
  const hotelButtonRef = useRef(null);
  //Selectors
  const { account_id = "" } = useSelector(
    (state) => state?.account?.account || {}
  );

  const { hotels = [], markets = [], biddingLevel='hotels' } = useSelector((state) => state?.editBid);
  const dispatch = useDispatch();

  const [openHotelMenu, setOpenHotelMenu] = useState(false);
  const [selectedHotelOrGroupType, setSelectedHotelOrGroupType] = useState(biddingLevel);

  const [selectedHotelsorGroups, setSelectedHotelsorGroups] = useState(hotels);
  const [selectedMarkets] = useState(markets);

  const [updateBidDesktopPayload, setUpdateBidDesktopPayload] = useState({
    id_account: account_id,
    bidding_level: selectedHotelOrGroupType === "hotels" ? "HOTEL" : "GROUP",
  });
  const [updateBidMobilePayload, setUpdateBidMobilePayload] = useState({
    id_account: account_id,
    bidding_level: selectedHotelOrGroupType === "hotels" ? "HOTEL" : "GROUP",
  });
  const [updateBidTabletPayload, setUpdateBidTabletPayload] = useState({
    id_account: account_id,
    bidding_level: selectedHotelOrGroupType === "hotels" ? "HOTEL" : "GROUP",
  });

  // List Data
  const [biddingList, setBiddingList] = useState({});
  const [showToast, setShowToast] = useState(false);
  const {
    hotel_list = [],
    group_list = [],
    market_list = [],
    bidding_rule_list = [],
    can_use_group,
  } = biddingList || {};
  const COMMONFORMFIELDS = [
    {
      id: "hotel",
      label: "Hotel",
      type: "select",
      placeholder: " Select a hotel ",
      options: hotel_list,
    },

    {
      id: "market_id",
      label: "Market",
      type: "select",
      placeholder: " Select a value ",
      options: market_list,
    },
  ];

  const [errors, setErrors] = useState({});

  // Service to render Bidding List based on Account Id
  useEffect(() => {
    if (account_id) {
      setUpdateBidDesktopPayload((prev) => ({
        ...prev,
        id_account: account_id,
      }));
      setUpdateBidMobilePayload((prev) => ({
        ...prev,
        id_account: account_id,
      }));
      axios
        .get(
          `${APP_GHA_BIDDING_API_ENDPOINT}/api/google-hotel-ads/bidding-panel-info/${account_id}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setBiddingList(res?.data || {});
        });
    }
  }, [account_id,token]);

  const handleChangeFieldValue = useCallback((field, value, device) => {
    if (device === "desktop") {
      setUpdateBidDesktopPayload((prev) => ({ ...prev, [field]: value }));
    } else if (device === "mobile") {
      setUpdateBidMobilePayload((prev) => ({ ...prev, [field]: value }));
    } else {
      setUpdateBidTabletPayload((prev) => ({ ...prev, [field]: value }));
    }
  }, []);

  const sendUpdateRequest = useCallback((payload) =>{
    axios
      .put(
        `${APP_GHA_BIDDING_API_ENDPOINT}/api/google-hotel-ads-meta/update-bids/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setShowToast(true);
      });
  }, [token]);

  useEffect(() => {
    handleChangeFieldValue(selectedHotelOrGroupType === "hotels"? "hotel_id": "group_id",hotels.map((s) => s.id),"desktop");
    handleChangeFieldValue(selectedHotelOrGroupType === "hotels"? "hotel_id": "group_id",hotels.map((s) => s.id),"mobile");
    handleChangeFieldValue(selectedHotelOrGroupType === "hotels"? "hotel_id": "group_id",hotels.map((s) => s.id),"tablet");
    handleChangeFieldValue("market_id",markets.map((s) => s.market_id),"desktop");
    handleChangeFieldValue("market_id",markets.map((s) => s.market_id),"mobile");
    handleChangeFieldValue("market_id",markets.map((s) => s.market_id),"tablet");
  }, [hotels, markets,selectedHotelOrGroupType,handleChangeFieldValue]);

  const handleSave = useCallback(() => {
    if (!validateBiddingRulesPayload(updateBidDesktopPayload, updateBidMobilePayload,updateBidTabletPayload,setErrors)) {
      const finalDesktopPayload = {...updateBidDesktopPayload};
      const finalMobilePayload = {...updateBidMobilePayload};
      const finalTabletPayload = {...updateBidTabletPayload};

      if (selectedHotelOrGroupType === "hotels") {
        delete finalDesktopPayload?.group_id;
        delete finalMobilePayload?.group_id;
        delete finalTabletPayload?.group_id;
      }
      if (selectedHotelOrGroupType === "groups") {
        delete finalDesktopPayload?.hotel_id;
        delete finalMobilePayload?.hotel_id;
        delete finalTabletPayload?.hotel_id;
      }

      if (
          finalDesktopPayload?.bidding_strategy?.bidding_rule_level_identifier &&
          finalDesktopPayload?.bidding_strategy?.bidding_rule_id !== undefined
      ) {
        sendUpdateRequest(finalDesktopPayload);
      }

      if (
          finalMobilePayload?.bidding_strategy?.bidding_rule_level_identifier &&
          finalMobilePayload?.bidding_strategy?.bidding_rule_id !== undefined
      ) {
        sendUpdateRequest(finalMobilePayload);
      }

      if (
          finalTabletPayload?.bidding_strategy?.bidding_rule_level_identifier &&
          finalTabletPayload?.bidding_strategy?.bidding_rule_id !== undefined
      ) {
        sendUpdateRequest(finalTabletPayload);
      }
    }
  }, [updateBidDesktopPayload, updateBidMobilePayload, updateBidTabletPayload,selectedHotelOrGroupType,sendUpdateRequest]);

  const biddingOptions = useMemo(() => {
    if (bidding_rule_list?.length) {
      return bidding_rule_list.map((rule) => ({
        id: rule.bidding_rule_id,
        label: rule.bidding_rule_name,
        value: rule.bidding_rule_name,
      }));
    }

    return [];
  }, [bidding_rule_list]);

  return (
    <div style={{ position: "relative" }}>
      <div className="form-title">EDIT YOUR BIDDING OPTIONS</div>
      <div className="form-button-container">
        <div className="formGrid">
          <div className="common-field-container">
            {COMMONFORMFIELDS.map((field) => {
              if (field.id === "market_id") {
                return (
                  <div className="form-grid-item">
                    {market_list?.length ? (
                      <MarketsFilterMenu
                        allMarkets={market_list?.map((market) =>
                          selectedMarkets?.find(
                            (m) => market?.market_id === m?.market_id
                          )
                            ? { ...market, isSelected: true }
                            : { ...market, isSelected: false }
                        )}
                        onApply={(selected) => {
                          dispatch(updateEditBidMarkets(selected));
                          handleChangeFieldValue(
                            "market_id",
                            selected.map((s) => s.market_id),
                            "desktop"
                          );
                          handleChangeFieldValue(
                            "market_id",
                            selected.map((s) => s.market_id),
                            "mobile"
                          );
                          handleChangeFieldValue(
                            "market_id",
                            selected.map((s) => s.market_id),
                            "tablet"
                          );
                        }}
                        error={errors.marketId}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                );
              }

              if (field.id === "hotel") {
                return (
                  <div className="form-grid-item bidding_multiplier_height">
                    <div className="mul-form-label">Hotels</div>
                    <div ref={hotelButtonRef}>
                      <TextInput
                        placeholder={field.placeholder}
                        type={"text"}
                        readOnly
                        value={
                          selectedHotelsorGroups.length > 0
                            ? selectedHotelsorGroups.length === 1
                              ? selectedHotelsorGroups[0].name
                              : `${selectedHotelsorGroups.length} ${selectedHotelOrGroupType} selected`
                            : ""
                        }
                        sx={{
                          backgroundColor: "white",
                          fontSize: "12px",
                          fontWeight: "400",
                          paddingLeft: "4px",
                        }}
                        endAdornment={<ArrowDropDown />}
                        onClick={(e) => {
                          setOpenHotelMenu(true);
                        }}
                        error={errors.hotelId}
                      />
                    </div>
                    {hotel_list?.length ? (
                      <HotelsFilterMenu
                        allHotels={hotel_list?.map((hotel) =>
                          selectedHotelsorGroups?.find((h) => h.id === hotel.id) || hotel_list.length ===1
                            ? { ...hotel, isSelected: true }
                            : { ...hotel, isSelected: false }
                        )}
                        allGroups={group_list?.map((group) =>
                          selectedHotelsorGroups?.find((h) => h.id === group.id)
                            ? { ...group, isSelected: true }
                            : { ...group, isSelected: false }
                        )}
                        showGroups={group_list.length !== 0 && can_use_group}
                        anchorEl={hotelButtonRef.current}
                        open={openHotelMenu}
                        onClose={() => {
                          setOpenHotelMenu(false);
                        }}
                        onApply={(selected) => {
                          dispatch(updateEditBidHotels(selected));
                          dispatch(updateBiddingLevel(selectedHotelOrGroupType));
                          setSelectedHotelsorGroups(selected);
                          handleChangeFieldValue(
                            selectedHotelOrGroupType === "hotels"
                              ? "hotel_id"
                              : "group_id",
                            selected.map((s) => s.id),
                            "mobile"
                          );
                          handleChangeFieldValue(
                            selectedHotelOrGroupType === "hotels"
                              ? "hotel_id"
                              : "group_id",
                            selected.map((s) => s.id),
                            "desktop"
                          );
                          handleChangeFieldValue(
                            selectedHotelOrGroupType === "hotels"
                              ? "hotel_id"
                              : "group_id",
                            selected.map((s) => s.id),
                            "tablet"
                          );
                        }}
                        setSelectedHotelOrGroupType={(type) => {
                          setSelectedHotelOrGroupType(type);
                          handleChangeFieldValue(
                              "bidding_level",
                              type === "hotels" ? "HOTEL" : "GROUP",
                              "desktop"
                          );
                          handleChangeFieldValue(
                              "bidding_level",
                              type === "hotels" ? "HOTEL" : "GROUP",
                              "mobile"
                          );
                          handleChangeFieldValue(
                            "bidding_level",
                            type === "hotels" ? "HOTEL" : "GROUP",
                              "tablet"
                          );
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div className="fields-container bidding_multiplier_height">
            {ghaBiddingRulesFormFields?.map((field) => {
              return (
                <div className="form-grid-item">
                  <FormFieldRenderer
                    item={field}
                    options={[...field?.options, ...biddingOptions] || []}
                    hotelMenuOpen={openHotelMenu}
                    onHotelMenuClose={() => {
                      setOpenHotelMenu(false);
                    }}
                    onChange={(val) => {
                      let device_identifier;
                      if (field.label.toLowerCase().includes("desktop")) {
                        device_identifier = "desktop";

                        handleChangeFieldValue(
                          "bidding_strategy",
                          {
                            ...updateBidDesktopPayload?.bidding_strategy,
                            bidding_type: "BIDDING_RULE",
                            bidding_rule_level_identifier: "DESKTOP",
                            bidding_rule_id: val
                              ? biddingOptions.find((opt) => opt.value === val)
                                  ?.id ?? 0
                              : undefined,
                          },
                          device_identifier
                        );
                      }
                      if (field.label.toLowerCase().includes("mobile")) {
                        device_identifier = "mobile";

                        handleChangeFieldValue(
                          "bidding_strategy",
                          {
                            ...updateBidMobilePayload?.bidding_strategy,
                            bidding_type: "BIDDING_RULE",
                            bidding_rule_level_identifier: "MOBILE",
                            bidding_rule_id: val
                              ? biddingOptions.find((opt) => opt.value === val)
                                  ?.id ?? 0
                              : undefined,
                          },
                          device_identifier
                        );
                      }
                      if (field.label.toLowerCase().includes("tablet")) {
                        device_identifier = "tablet";

                        handleChangeFieldValue(
                          "bidding_strategy",
                          {
                            ...updateBidTabletPayload?.bidding_strategy,
                            bidding_type: "BIDDING_RULE",
                            bidding_rule_level_identifier: "TABLET",
                            bidding_rule_id: val
                              ? biddingOptions.find((opt) => opt.value === val)
                                  ?.id ?? 0
                              : undefined,
                          },
                          device_identifier
                        );
                      }
                    }}
                    labelKey={"label"}
                    errors={errors}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div style={{display: 'flex', gap: 10, marginBottom:'-20px'}}>
          <CustomButtonWithIcon
              color="#b0acac"
              hoverColor="#12794F"
              titleColor="#373F41"
              hoverTitleColor={"white"}
              width={"85px"}
              height={"24px"}
              borderRadius="20px"
              textStyle={{
                fontFamily: 'Inter',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '14.52px',
                textAlign: 'center',
              }}
              onClick={handleSave}
          >
            Save
          </CustomButtonWithIcon>
          <CustomButtonWithIcon
              color="#b0acac"
              hoverColor="#12794F"
              titleColor="#373F41"
              hoverTitleColor={"white"}
              width={"85px"}
              height={"24px"}
              borderRadius="20px"
              textStyle={{
                fontFamily: 'Inter',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '14.52px',
                textAlign: 'center',
              }}
              onClick={() => {
                dispatch(setOpenEditBidding(false));
              }}
          >
            Cancel
          </CustomButtonWithIcon>
        </div>
        {/* )} */}
      </div>
      <Snackbar
          open={showToast}
          autoHideDuration={3000}
          onClose={() => {
            setShowToast(false);
          }}
      >
        <Alert severity="success" variant="filled" sx={{width: "100%"}}>
          Bidding Rules were saved
        </Alert>
      </Snackbar>
    </div>
  );
};

export default GHABidddingRules;
