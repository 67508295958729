import ERROR_MESSAGES from '../Constant/errorMessages';

export const handleApiResponse = (response, options) => {
    const { isEdit, showSnackbar, setErrors, onClose } = options;
    const API_RESPONSE = isEdit ? "RESOURCE_UPDATED" : "RESOURCE_CREATED";
    const API_MESSAGE = isEdit ? "updated" : "created";
    if (response.success) {
        switch (response.status_code) {
            case 200:
            case 201:
                if (response.data && response.data.message === API_RESPONSE) {
                    if (showSnackbar) {
                        showSnackbar(
                            `Report ${API_MESSAGE} successfully! Report ID: ${response.data.report_id}`,
                            "success"
                        );
                    }
                    if (onClose) {
                        onClose();
                    }
                } else if (showSnackbar) {
                    showSnackbar(
                        `Unexpected success response. Status Code: ${response.status_code}`,
                        "info"
                    );
                }
                break;

            default:
                if (showSnackbar) {
                    showSnackbar(
                        `Unhandled success status code: ${response.status_code}`,
                        "info"
                    );
                }
        }
    } else {
        switch (response.status_code) {
            case 400:
                if (response.errors && response.errors.length > 0) {
                    const fieldErrors = {};
                    response.errors.forEach((err) => {
                        const translatedMessage = ERROR_MESSAGES[err.message] || err.message;
                        if (err.property_path) {
                            fieldErrors[err.property_path] = translatedMessage;
                            if (err.property_path === "level_choices") {
                                fieldErrors["level"] = translatedMessage;
                            }
                            if (err.property_path === "custom_start_date") {
                                fieldErrors["date_range"] = translatedMessage;
                            }
                        } else {
                            fieldErrors["unspecified"] = translatedMessage;
                        }
                    });
                    setErrors(fieldErrors);
                }
                break;

            case 401:
                if (showSnackbar) {
                    showSnackbar("Unauthorized. Please log in again.", "error");
                }
                break;

            case 403:
                if (showSnackbar) {
                    showSnackbar("Forbidden. You don't have access to this resource.", "error");
                }
                break;

            case 404:
                if (showSnackbar) {
                    showSnackbar("Resource not found.", "error");
                }
                break;

            case 500:
                if (showSnackbar) {
                    showSnackbar("Internal server error. Please try again later.", "error");
                }
                break;

            case 429:
                if (showSnackbar) {
                    showSnackbar("Too many requests. Please slow down.", "error");
                }
                break;

            default:
                if (showSnackbar) {
                    showSnackbar(
                        `Unexpected error occurred (Status Code: ${response.code}).`,
                        "error"
                    );
                }
        }
    }
};
