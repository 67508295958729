import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hotels: [],
  markets: [],
  openEditBidding: false,
  biddingLevel:"hotels",
};
export const editBidSlice = createSlice({
  name: "editBidSlice",
  initialState: initialState,
  reducers: {
    updateEditBidHotels: (state, action) => {
      state.hotels = action.payload;
    },
    updateEditBidMarkets: (state, action) => {
      state.markets = action.payload;
    },
    setOpenEditBidding : (state, action) => {
      state.openEditBidding = action.payload;
    },
    updateBiddingLevel : (state, action) => {
      state.biddingLevel = action.payload;
    },
    resetEditBid: () => initialState
  },
});

export const { updateEditBidHotels, updateEditBidMarkets,setOpenEditBidding,updateBiddingLevel,resetEditBid } =
  editBidSlice.actions;
export default editBidSlice.reducer;
