import "./AccountDropdown.scss";
import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Menu, TextField } from "@mui/material";
import {APP_ACCOUNT_API_ENDPOINT, APP_HOTELS_API_ENDPOINT} from "../../../constants/api_urls";
import axios from "axios";
import DownArrowBig from "assets/images/DownArrowBig.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateAccount, updateAccountList, updateCanUseGroups, updateGroupId, updateGroupList, updateHotelId, updateHotelList, updateLevel } from "store/slices/account/accountSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {useParams} from "react-router-dom";
import {setActiveSection} from "../../../store/slices/sidebar/sidebar";
import {getUserToken} from "../../../utils/functions/userToken";

export default function AccountDropdown() {
  const selectedAccount = useSelector((state) => state?.account?.account || {});
  const { hotel_id = [], accounts = [], level } = useSelector((state) => state?.account || {});
  const [selectedValues, setSelectedValues] = useState(null);
  const [hasUserSelected, setHasUserSelected] = useState(false);
  const [searchedAccounts, setSearchedAccounts] = React.useState([]);
  const token = getUserToken() || "";
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { idaccount } = useParams();
  const dispatch = useDispatch();
  React.useEffect(() => {
    if(!accounts?.length){
      axios
      .get(`${APP_ACCOUNT_API_ENDPOINT}/api/accounts/list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
          dispatch(updateAccountList(res?.data))
          setSearchedAccounts(res.data);
      });
    }
  }, [accounts, dispatch, token]);

  React.useEffect(() => {
    const searchedAccountId = idaccount;
    if (searchedAccountId && searchedAccountId !== "undefined") {
      const searchedAccount = accounts?.find(
        (ac) => ac.account_id.toString() === searchedAccountId.toString()
      );


      if (searchedAccount) {
        setSelectedValues(searchedAccount);
        dispatch(updateAccount(searchedAccount));
        if (searchedAccount?.account_id) {
          axios
            .get(
              `${APP_HOTELS_API_ENDPOINT}/api/hotels/list?id_account=${searchedAccount?.account_id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              const page = window.location.pathname
              dispatch(updateHotelList(res?.data?.data || []));
              const isHotelIdCorrect = Array.from(hotel_id || []).every(id => res?.data?.data.map((h) => h?.["hotel_id"])?.includes(id))
              if (res?.data?.data.length > 0 && (!isHotelIdCorrect || !hotel_id.length) && (level !== "GROUP" || page.includes('searchads') || page.includes('pMax'))) {
                dispatch(updateHotelId(res?.data?.data.map((h) => h?.["hotel_id"])));
                dispatch(updateGroupId([]))
              }
            });
    
          axios
            .get(
              `${APP_HOTELS_API_ENDPOINT}/api/group/list?id_account=${searchedAccount?.account_id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              dispatch(updateGroupList(res?.data?.['group_list'] || []));
              dispatch(updateCanUseGroups(res?.data?.['can_use_groups']))
            });
        }
      }
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idaccount, accounts, dispatch, token]);

  React.useEffect(() => {
    if (Object.keys(selectedAccount || {}).length) {
      setSelectedValues(selectedAccount);
    }
  }, [selectedAccount]);

  const handleSearch = (e) => {
    const search = e.target.value.toLowerCase();
    if (search === "a" || search === "A") {
      e.preventDefault();
    }
    if (search.length === 0) {
      setSearchedAccounts(accounts);
      return;
    }

    const tempData = [...accounts];
    setSearchedAccounts(
      tempData?.filter((item) => {
        const accountId = item?.account_id?.toString().toLowerCase();
        const accountName = item?.account_name?.toString().toLowerCase();
        if(item?.search_by_hotel === true) {
            const hotelIds = item?.hotel_ids?.toString().toLowerCase();
            return !!(accountId?.includes(search) || accountName?.includes(search) || hotelIds?.includes(search));
        }else{
            return !!(accountId?.includes(search) || accountName?.includes(search));
        }
      })
    );
  };
  const [isOpen, setIsOpen] = React.useState(false);
  const accountRef = React.useRef(null);
  const [hoveredItem, setHoveredItem] = useState("");

  useEffect(() => {
    if (searchedAccounts.length > 0 && !hasUserSelected && !selectedAccount) {
      const lastAccount = searchedAccounts[searchedAccounts.length - 1];
      setSelectedValues(lastAccount);
      setHasUserSelected(true);
      dispatch(updateAccount(lastAccount));
    }
  }, [searchedAccounts, hasUserSelected, selectedAccount, dispatch]);

  return (
    <Box sx={{ minWidth: 120 }} className={"account"}>
      <FormControl sx={{ minWidth: 170 }} className="accountDropdown">
        <div
          className="selected"
          ref={accountRef}
          onClick={() => {
            setSearchedAccounts(accounts);
            setIsOpen(!isOpen);
          }}
        >
          {" "}
          <div
            className="selected-label"
            onMouseEnter={() => setHoveredItem("accountDropDown")}
            onMouseLeave={() => setHoveredItem("")}
          >
            <div
                className="accountHoverState"
                style={{
                  backgroundColor: hoveredItem === "accountDropDown" ? "#61DDAE" : "#61D5A9",
                  border: hoveredItem === "accountDropDown" ? "1px solid #000" : '1px solid #000',
                }}
            >
              <div style={{paddingLeft: "10px", width: '385px'}}>
                {selectedValues?.['account_name'] ?? "Account"}
              </div>
              <img
                  alt={"DownArrow"}
                  width="10px"
                  height="9px"
                  src={DownArrowBig}
                  style={{margin: "0 9px 0px 0"}}
              />
            </div>
          </div>
          {" "}
        </div>
        <Menu
            labelId="select-label"
            open={isOpen}
            id="menu"
            anchorEl={accountRef.current}
            value={selectedValues}
          onClose={() => {
            setIsOpen(false);
          }}
          IconComponent={(props) => <img alt={"DownArrowBig"} src={DownArrowBig} {...props} />}
          slotProps={{
              paper: {
            sx: {
              marginTop: "-25px",
              minWidth: 190,
              width: 414,
              borderRadius: "12px",
              overflowX: "hidden",
              whiteSpace: "nowrap",
              color: "#000000",
              fontFamily: "Inter",
            },}
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div
              aria-disabled
              className="accountHoverState"
              style={{backgroundColor: "#fff", marginBottom: "10px",display:'flex'}}
          >
            <div
                style={{
                  paddingLeft: "10px",
                  fontSize: "12px",
                  fontWeight: "400",
                  width: '385px',
                  fontFamily:'inter'
                }}
            >
              {selectedValues?.['account_name'] ?? "Account"}
            </div>
            <img
                width="10px"
                height="9px"
                alt={"Down"}
                src={DownArrowBig}
                style={{margin: "2px 14px 0px 0"}}
            />
          </div>

          <TextField
              sx={{
                  '& .MuiInputBase-input': {
                      fontSize: '12px',
                      fontFamily: "Inter",
                      fontWeight: "400"
                  },
                "& ::placeholder": {
                  fontSize: "10px",
                },
              }}
              variant="standard"
            onChange={(e) => {
              handleSearch(e);
            }}
            placeholder="Search"
            className="select-search"
            inputProps={{
              autoComplete: "off",
            }}
          />
          <Box
            sx={{
              maxHeight: "300px",
              overflowY: "auto",
              marginRight: "5px",
              overflowX: "hidden",
            }}
          >
            {[...searchedAccounts]?.map((account) => {
              return (
                  <MenuItem
                      sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          fontFamily: "inter",
                          color: "#000000",
                      }}
                      value={account.account_id}
                      onClick={() => {
                          dispatch(updateAccount(account));
                          dispatch(updateLevel("HOTEL"));
                          dispatch(updateGroupId([]));
                          dispatch(updateHotelId([]));

                          const redirectToHomeRoutes = [
                              "bidding-offline",
                              "groups",
                              "searchads",
                              "sign-up-sem",
                              "sign-up-pmax",
                              "bidding-center",
                              "search-ads-campaign",
                              "search-ads-campaign/create",
                              "pmax-campaign-manager",
                              "pmax-campaign-manager/create",
                              "keywords-reporting",
                              "search-terms-reporting",
                          ];

                          const basePath = pathname.split("/")[1];

                          if (redirectToHomeRoutes.includes(basePath)) {
                              dispatch(setActiveSection(null));
                              navigate(`/home/${account.account_id}`);
                          } else {
                              const updatedPath = pathname.replace(
                                  /\/\d+(?=\/|$)/,
                                  `/${account.account_id}`
                              );
                              navigate(updatedPath);
                          }
                          setIsOpen(false);
                      }}
                  >
                  {" "}
                  {account.account_name}{" "}
                </MenuItem>
              );
            })}
          </Box>
        </Menu>
      </FormControl>
    </Box>
  );
}
