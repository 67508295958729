import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ColumnsChangeIcon from "assets/images/ColumnsChangeIcon.svg";
import { Alert, Box, Chip, IconButton, Snackbar, Tooltip } from "@mui/material";
import {useEffect, useRef, useState} from "react";
import ColumnSelectionMenu from "./ColumnSelectionMenu";
import CollapseArrow from "assets/images/collapseArrow.svg";
import CheckboxMenu from "components/CheckboxMenu";
import {
    allPlatformsSegmentOptions,
    keywordReportingSegmentOptions,
    pMaxSegmentOptions,
    searchAdsSegmentOptions,
    searchTermReportingSegmentOptions,
    segmentMenuOptions,
} from "mock/filterOptions";
import edit from "assets/images/edit.svg";
import { CloseOutlined } from "@mui/icons-material";
import { updateSelectedSegments as metaSearchUpdateSegment, updateSegmentList as metaSearchUpdateDefaultSegmentList} from "store/slices/metaSearch/metaSearch";
import { updateSelectedSegments as searchAdsUpdateSegment, updateSegmentList as searchAdsUpdateDefaultSegmentList, 
  updateKeywordsReportingSelectedSegments, updateSearchTermReportingSelectedSegments,
   updateKeywordsReportingSegmentList, updateSearchTermReportingSegmentList } from "store/slices/searchAds/searchAds";
import { updateSelectedSegments as pMaxUpdateSegment, updateSegmentList as pMaxUpdateDefaultSegmentList } from "store/slices/pMax/pMax";
import { useDispatch, useSelector } from "react-redux";
import DmpIcons from "../DmpIcons/icons";
import ReportingCustomPagination from "components/Reporting/ReportingCustomPagination/index";

const segmentOptionsForPage = {
  searchAds: searchAdsSegmentOptions,
  metaSearch: allPlatformsSegmentOptions,
  pMax: pMaxSegmentOptions,
  searchAdsKeywords: keywordReportingSegmentOptions,
  searchAdsSearchTerm: searchTermReportingSegmentOptions
}

const Table = ({
  page,
  rows,
  columns,
  selectedColumns,
  handleColumnSelectionChange,
  isChartCollapsed,
  setIsChartCollapsed,
  maxHeight,
  onEditClick,
  isPaginationEnabled=false,
  paginationData,
  handlePagination,
  handleSorting,
  isAccountWritable,
  isLoading = false,
  selectedSegments = [],
  selectedPlatform = [],
  noBiddingOption= false,
}) => {
  const [isColumnMenuOpen, setIsColumnMenuOpen] = useState(false);
  const tableContainerRef = useRef();
  const stickyDivRef = useRef();
  const segmentMenuRef = useRef(null);
  const [segmentSelection, setSegmentSelection] = useState(selectedSegments);
  const [openSegmentMenu, setOpenSegmentMenu] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const can_use_groups = useSelector(state=>state.account.can_use_groups)
  const dispatch = useDispatch();
  const { platform = [] } = useSelector((state) => state?.metaSearch);
  const { level, hotel_id = [] } = useSelector((state) => state?.account);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    });

    const handleChangePage = (event, newPage) => {
        const pageIndex = newPage - 1;

        setPagination((prev) => {
            const newPagination = {
                ...prev,
                pageIndex: pageIndex,
            };
            handlePagination(newPagination);
            return newPagination;
        });
    };

    const handleRowsPerPageChange = (event) => {
        const newPageSize = parseInt(event.target.value, 10) || 10;
        setPagination((_prev) => {
            const newPagination = {
                pageIndex: 0,
                pageSize: newPageSize,
            };
            handlePagination(newPagination);
            return newPagination;
        });
    };

    useEffect(() => {
        setPagination((prev) => {
            const newPagination = { ...prev, pageIndex: 0 };
            handlePagination(newPagination);
            return newPagination;
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(selectedSegments)]);

    const segmentFunctions = {
    metaSearch: metaSearchUpdateSegment,
    pMax: pMaxUpdateSegment,
    searchAds: searchAdsUpdateSegment,
    searchAdsKeywords: updateKeywordsReportingSelectedSegments,
    searchAdsSearchTerm: updateSearchTermReportingSelectedSegments
  }

  const defaultSegmentFunctions = {
    metaSearch: metaSearchUpdateDefaultSegmentList,
    pMax: pMaxUpdateDefaultSegmentList,
    searchAds: searchAdsUpdateDefaultSegmentList,
    searchAdsKeywords: updateKeywordsReportingSegmentList,
    searchAdsSearchTerm: updateSearchTermReportingSegmentList
  }

  const ghaPlatformSegmentList = () => {
    const platforms = platform?.map(p => p?.channel_id);
  
    const areAllPlatformsSupported = (platformsArray) => {
      const supportedPlatforms = ["GHA_META", "GHA_PPA", "GHA_FBL"];
      return platformsArray.every(p => supportedPlatforms.includes(p));
    };
  
    if (platforms?.length === 2) {
      const [firstPlatform, secondPlatform] = platforms;
  
      if (["GHA_META", "GHA_PPA"].includes(firstPlatform) && ["GHA_META", "GHA_PPA"].includes(secondPlatform)) {
        return segmentMenuOptions["allPlatformsSegmentOptions"];
      }
  
      if (["GHA_META", "GHA_FBL"].includes(firstPlatform) && ["GHA_META", "GHA_FBL"].includes(secondPlatform)) {
        return segmentMenuOptions["allPlatformsSegmentOptions"];
      }
    }
  
    if (platforms?.length === 3 && areAllPlatformsSupported(platforms)) {
      return segmentMenuOptions["allPlatformsSegmentOptions"];
    }
  };

  const calculateDynamicColumnWidth = (column) => {
    let maxLength = 0;
    rows?.forEach(row => {
      const cellValue = row[column.accessorKey] || '';
      maxLength = Math.max(maxLength, cellValue.length)
    })

    return maxLength*6.5+10;
  }
  

  const updateSegmentList = segmentFunctions?.[page] || metaSearchUpdateSegment;
  const updateDefaultSegmentList = defaultSegmentFunctions?.[page] || metaSearchUpdateDefaultSegmentList;
  const table = useMaterialReactTable({
    columns: selectedColumns
      ?.filter((item) => item.isSelected)
      ?.map((column) => {
        if (column?.type === "SEGMENT") {
          
          return {
            ...column,
            sortDescFirst: true,
            minSize: calculateDynamicColumnWidth(column),
            maxSize: calculateDynamicColumnWidth(column),
            muiTableBodyCellProps: ({ cell,row,table }) => {
                const { columnPinning } = table.getState();

                const isPinnedLeft = cell.column.getIsPinned?.() === 'left';

                const pinnedLeft = columnPinning?.left ?? [];

                const isLastPinnedLeft =
                    isPinnedLeft && pinnedLeft[pinnedLeft.length - 1] === cell.column.id;
              return {
                sx: {
                  fontSize: "12px",
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: cell.row.original.id === "0" ? "700" : "400",
                    ...(isLastPinnedLeft && {
                        borderRight: "1px solid #E1E1E1"
                    }),
                  textAlign: "start",
                  width: "fit-content !important",
                  boxShadow: "unset",
                },
              };
            },
          };
        }
        return {
          ...column,
          maxSize: 60,
          sortDescFirst: true,
          muiTableBodyCellProps: ({ cell, row }) => {
            return {
              sx: {
                fontSize: "12px",
                fontFamily: "Roboto, sans-serif",
                fontWeight: cell.row.original.id === "0" ? "700" : "400",
                color:  Number(row?.original?.[
                  `${cell.column?.columnDef?.accessorKey}_compare_percent`
                ]?.toString()?.split(" ")?.[0]?.replace(/,/g,"")) < 0 ? "red": "black",
                textAlign: "end",
              },
            };
          },
        };
      }),
    data: rows,
    getRowId: (row) => {
      return row.id;
    },
    getSubRows: (row) => row.subRows,
    onPaginationChange: (paginationFn) => {
      setPagination((prev) => {
        const newPagination = paginationFn(prev);
        if (
          newPagination?.pageIndex !== 0 &&
          newPagination?.pageSize !== prev?.pageSize
        ) {
          newPagination.pageIndex = 0;
        }
        handlePagination(newPagination);
        return newPagination;
      });
    },
    muiPaginationProps: {
      size: "small",
},
    pageCount: paginationData?.total_number_of_page,
    rowCount: paginationData?.total_number_of_rows,
    enablePinning: true,
    state: {
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      },
      expanded:true,
      density: "compact",
      rowPinning: { top: ["0"] },
      isLoading: isLoading,
      showSkeletons: isLoading,
      sorting: sorting,
      columnPinning: {
        left: [...selectedColumns
          ?.filter((c) => c?.type === "SEGMENT")
          ?.map((col) => col?.id)], right: []
      },
    },
    enableSorting: true,
    onSortingChange: (sortingFn) => {
      setSorting((prev) => {
        handleSorting(
          sortingFn(prev)?.map((sort) => ({
            ...sort,
            id: columns?.find((col) => col?.id === sort?.id)?.accessorKey,
          }))
        );
        return sortingFn(prev);
      });
    },
    muiTableBodyProps: () => {
      return {
        sx: {
          "& .MuiTableRow-root td:after": {
            backgroundColor: "white",
          },
            "& .MuiTableRow-root td:before": {
                backgroundColor: "white",
            },
        },
      };
    },

      muiTableHeadCellProps: ({ column }) => {
      const isFirstColumn = column.id === "0";
      return {
        sx: {
          textAlign: column?.columnDef?.type === "SEGMENT" ? "left" : "end",
          paddingLeft: isFirstColumn ? "18px" : undefined,
          paddingBlock: "10px",
          "&:hover": {
            "& .MuiTableSortLabel-icon": {
              opacity: "1 !important",
            },
          },
          "& .Mui-TableHeadCell-Content": {
            display: "flex",
            justifyContent:
              column?.columnDef?.type === "SEGMENT" ? "flex-start" : "flex-end",
          },
          "& .Mui-TableHeadCell-Content-Labels": {
            flexDirection:
              column?.columnDef?.type === "SEGMENT" ? "row" : "row-reverse",
            display: "inline-flex",
            width: "max-width",
          },
          "& .Mui-TableHeadCell-Content-Wrapper": {
            fontSize: "12px",
            fontWeight: "600",
            fontFamily: "Inter, sans-serif",
            color: "black",
            display: "inline-block",
            whiteSpace: "nowrap",
            lineHeight: "15px",
          },
          "& .MuiTableSortLabel-icon": {
            flex: 1,
            opacity: "0 !important",
          },
        },
      };
    },

    muiTableHeadProps: {
      ref: stickyDivRef,
      sx: {
        "& .MuiTableRow-root": {
          boxShadow: "unset !important",
        },
        '& .MuiTableCell-root[data-pinned="true"]:before': {
          boxShadow: "unset",
          opacity: 1,
          backgroundColor: "white",
        },
      },
    },

    muiTableBodyRowProps: ({ _row }) => {
      return {
          sx: {
              '&:hover': {
                  backgroundColor: "#FAFAFA",
              },
              '& td[data-pinned="true"]': {
                  '&:before': {
                      boxShadow: "unset",
                      backgroundColor: "white",
                  },

              },
          },
        hover: true,
      };
    },
    muiExpandAllButtonProps: {
      sx: {
        display: "none",
      },
    },
    muiTopToolbarProps: {
      sx: {
        alignItems: "center",
        "& .MuiBox-root": {
          paddingBlock: "0px !important",
        },
      },
    },
    enableTopToolbar: false,
    enableColumnActions: false,
    enableExpanding: true,
    enableExpandAll: true,
    enableBottomToolbar: false,
    positionExpandColumn: "last",
    enableColumnPinning: true,
    enableRowPinning: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enableGlobalFilter: false,
    enableStickyHeader: true,
    rowPinningDisplayMode: "sticky",
    icons: {
      ExpandMoreIcon: () => (
        <ArrowDropDownIcon
          sx={{
            fontSize: 20,
            paddingInline: "0px",
            margin: "0px",
            alignItems: "center",
          }}
        />
      ),
      ViewColumnIcon: () => (
        <div
          style={{ display: "flex", flexDirection: "column", marginRight: 30 }}
        >
          {" "}
          <span style={{ fontSize: "10px", color: "black" }}>Columns</span>{" "}
          <img style={{ maxHeight: "27px" }} src={ColumnsChangeIcon} alt="" />{" "}
        </div>
      ),
    },
    displayColumnDefOptions: {
       "mrt-row-pin": {
         muiTableHeadCellProps: {
           sx: {
             display: "none",
           },
         },
         muiTableBodyCellProps: {
           sx: {
             display: "none",
           },
         },
       },
      "mrt-row-expand": {
        maxSize: 15,
        muiTableBodyCellProps: ({ cell }) => {
          return {
            sx: {
              
              "& button": {
                padding: "4px",
                display:
                  cell.row.depth !== 0 || cell.row.id === "0" ? "none" : "none",
              },
            },
          };
        },
      },
    },

    enableTableFooter: true,
    enablePagination: false,
    manualPagination: true,
    manualSorting: true,
    muiSkeletonProps: {
      animation: "wave",
    },
    muiLinearProgressProps: {
      color: "secondary",
    },
    muiTableContainerProps: {
      sx: {
        width: "100%",
        height: maxHeight,
        maxHeight: maxHeight,

        "&::-webkit-scrollbar": {
          width: "6px",
          height: "6px",
        },

        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },

        "&::-webkit-scrollbar-thumb": {
          background: "#acabab",
          borderRadius: "4px",
        },

        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
          height: "10px",
        },
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: "none",
        borderRadius: "0",
        height: "100%",
      },
    },
  });
  const handleSegmentDelete = (id) => {
    const tempData = [...segmentSelection];
    const index = tempData.findIndex((i) => i.id === id);
    tempData.splice(index, 1);
    if (index !== -1) {
      setSegmentSelection(tempData);
      dispatch(updateSegmentList(tempData.map((s) => s.id)));
      dispatch(updateDefaultSegmentList([]))
    }
  };

    useEffect(() => {
        setSegmentSelection(
            selectedSegments
                .map((id) => {
                    const segment =
                        page === "metaSearch"
                            ? selectedPlatform.length > 1 ? allPlatformsSegmentOptions.find(s => s.id === id) : segmentMenuOptions[selectedPlatform?.[0]?.id]?.find((s) => s.id === id)
                            : (segmentOptionsForPage?.[page] || []).find((s) => s.id === id);

                    return segment ? { id: segment.id, label: segment.label } : null;
                })
                .filter(Boolean),
        );
    }, [selectedSegments, page, selectedPlatform]);
const getSegmentOptions = ()=>{
  
const options = (page === "metaSearch"
  ? platform?.length > 1 || platform?.length === 0
    ? ghaPlatformSegmentList() ?? allPlatformsSegmentOptions
    : segmentMenuOptions?.[platform?.[0]?.id]
  : segmentOptionsForPage?.[page] || allPlatformsSegmentOptions
)?.map((smo) =>
  segmentSelection.find((item) => item.id === smo.id)
    ? { ...smo, isSelected: true }
    : { ...smo, isSelected: false }
)
if(can_use_groups===false || level!=='GROUP'){
  return options.filter(op=>{
   return op.id!=='GROUPS'
  })
}
return options
}
  return (
    <>
      <div
        style={{
          backgroundColor: "#f2f2f2",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "16px",
          paddingBlock: "4px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
              {segmentSelection?.map((item) => {
                return (
                  <div
                    onClick={(e) => {
                      segmentMenuRef.current = e.target;
                      setOpenSegmentMenu((prev) => !prev);
                    }}
                  >
                    <Chip
                      variant="outlined"
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "400",
                        height: "22px",
                        marginRight: "8px",
                        ":hover": {
                          backgroundColor: "#E9E9E9",
                          cursor: "pointer",
                          border: "1px solid #000000",
                        },
                      }}
                      label={item.label}
                      deleteIcon={
                        <CloseOutlined
                          sx={{
                            fontSize: "14px",
                            height: "14px",
                            width: "14px",
                            marginTop: "4px",
                          }}
                        />
                      }
                      onDelete={() => {
                        handleSegmentDelete(item.id);
                      }}
                    >
                      {item.label}
                    </Chip>
                  </div>
                );
              })}
              <div
                onClick={(e) => {
                  segmentMenuRef.current = e.target;
                  setOpenSegmentMenu(!openSegmentMenu);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "8px",
                  padding: "0 8px 0 0",
                }}
                ref={segmentMenuRef}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#E5E5E5")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "inherit")
                }
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  marginRight={"5px"}
                  height={"14px"}
                  width={"14px"}
                  border={"0.5px solid #bab7b7"}
                  borderRadius={"50%"}
                >
                  <img
                    width={"6px"}
                    height={"6px"}
                    alt="AddIcon"
                    src={DmpIcons.ActionsIconsSVG.AddIconSVG}
                  />
                </Box>
                <span
                  style={{
                    fontFamily: "Inter",
                    fontWeight: "400",
                    color: "#BAB7B7",
                    fontSize: "10px",
                  }}
                >
                  Add segment
                </span>
              </div>
            </div>
          </Box>
        </Box>

        {openSegmentMenu && (
          <CheckboxMenu
            options={getSegmentOptions()}
            open={openSegmentMenu}
            onClose={() => {
              setOpenSegmentMenu(false);
            }}
            onApply={(selection) => {
              if (selection?.length <= 3) {
                if (
                  selection.map((s) => s.id).includes("HOTELS") &&
                  selection.map((s) => s.id).includes("GROUPS")
                ) {
                  setToastMessage(
                    "Hotels and Groups cannot be selected at once."
                  );
                  setToastType("error");
                  setShowToast(true);
                } else {
                  setSegmentSelection(
                    selection.map((s) => ({ id: s.id, label: s.label }))
                  );
                  dispatch(updateSegmentList(selection.map((s) => s.id)));
                  dispatch(updateDefaultSegmentList([]))
                }
              } else {
                setToastMessage("More than 3 segments cannot be selected.");
                setToastType("error");
                setShowToast(true);
              }
            }}
            anchorEl={segmentMenuRef.current}
            filterType="Segment"
          />
        )}
        <div style={{ display: "flex" }}>
          {page === "metaSearch" && !noBiddingOption && isAccountWritable && (
            <Tooltip title="Bidding Panel">
              <IconButton sx={{padding: 0}} disableRipple onClick={onEditClick}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <img src={edit} alt="" />{" "}
                </div>
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Metrics Selection" placement="bottom-end">
            <IconButton
              disableRipple
              onClick={() => {
                setIsColumnMenuOpen(true);
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <img src={ColumnsChangeIcon} alt="" />{" "}
              </div>
            </IconButton>
          </Tooltip>

          <IconButton
            disableRipple
            sx={{
              marginRight: "6px",
              marginLeft: "-4px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
            onClick={() => {
              setIsChartCollapsed(!isChartCollapsed);
            }}
          >
            <img
              src={CollapseArrow}
              alt={"CollapseArrow"}
              style={{
                transform: isChartCollapsed ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </IconButton>
        </div>
      </div>
      <div ref={tableContainerRef}>
        <MaterialReactTable
          table={table}
          // enableRowVirtualization
        />
      </div>
      <ReportingCustomPagination
        rowCount={paginationData?.total_number_of_rows}
        isPaginationEnabled={isPaginationEnabled}
        pageCount={paginationData?.total_number_of_page ?? 1}
        page={pagination.pageIndex + 1}
        handleChangePage={handleChangePage}
        rowsPerPage={pagination.pageSize}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
      <ColumnSelectionMenu
        open={isColumnMenuOpen}
        columns={columns}
        handleClose={() => {
          setIsColumnMenuOpen(false);
        }}
        onApply={(columns) => {
          handleColumnSelectionChange(columns);
          setIsColumnMenuOpen(false);
        }}
      />
      <Snackbar
        open={showToast}
        autoHideDuration={7000}
        onClose={() => {
          setShowToast(false);
        }}
      >
        <Alert severity={toastType} variant="filled" sx={{ width: "100%" }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
export default Table;
