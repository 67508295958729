import {useEffect, useState} from "react";
import axios from "axios";
import {APP_LOGIN_API_ENDPOINT} from "../../../constants/api_urls";
import {resetCalendar} from "../../../store/slices/calender/calenderSlice";
import {resetMetaSearch} from "../../../store/slices/metaSearch/metaSearch";
import {resetSearchAds} from "../../../store/slices/searchAds/searchAds";
import {resetPMax} from "../../../store/slices/pMax/pMax";
import {resetAccount} from "../../../store/slices/account/accountSlice";
import {useDispatch} from "react-redux";
import {resetEditBid} from "../../../store/slices/editBid/editBid";

const useAutoLogin = () => {
    const dispatch = useDispatch();
    const [loginError, setLoginError] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const getAutoLoginToken = () => {
            const params = new URLSearchParams(window.location.search);
            return params.get("autoltoken");
        };

        const autoLogin = async () => {
            const autoltoken = getAutoLoginToken();
            if (!autoltoken) return;

            try {
                const response = await axios.post(`${APP_LOGIN_API_ENDPOINT}/api/autologin`, { autoltoken });

                if (response.data.success) {
                    // todo : create function clear session to centralize with logout
                    localStorage.clear();
                    dispatch(resetCalendar());
                    dispatch(resetMetaSearch());
                    dispatch(resetSearchAds());
                    dispatch(resetPMax());
                    dispatch(resetAccount());
                    dispatch(resetEditBid());

                    const data = response.data;
                    localStorage.setItem("user_refresh_token", data.refresh_token);
                    localStorage.setItem("user_token", data.token);
                    localStorage.setItem("user_username", data.username);
                    setIsLoggedIn(true);
                }
            } catch (error) {
                setLoginError(error.response.data.errors?.[0] ?? "An error occurred. Please try again.");
            }
        };

        autoLogin();
    }, []);

    return { isLoggedIn, loginError };
};

export default useAutoLogin;
