import "./Navbar.scss";
import React, {useState} from "react";
import AccountDropdown from "./AccountDropdown";
import {Link, useNavigate} from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
//constants
import {PRODUCT_MENU_LIST} from "../../constants/productmenu";
// images
import LogoIcon from "../../assets/images/logo.svg";
import AppsIcon from "../../assets/images/apps.svg";
import {getUserToken} from "../../utils/functions/userToken";
import {getUserDetailsFromToken} from "../../utils/functions/userTokenDetails";
import IconUser from "../../assets/images/iconUser.svg";
import IconWrapper from "../IconWrapper";
import {useDispatch} from "react-redux";
import {resetCalendar} from "store/slices/calender/calenderSlice";
import {resetMetaSearch} from "store/slices/metaSearch/metaSearch";
import {resetSearchAds} from "store/slices/searchAds/searchAds";
import {resetPMax} from "store/slices/pMax/pMax";
import {APP_DMP_ADMIN_CENTER_URL} from "../../constants/api_urls";
import {resetAccount} from "store/slices/account/accountSlice";
import {resetEditBid} from "../../store/slices/editBid/editBid";


export default function Navbar() {
    const navigate = useNavigate();
    const {UserDetails} = getUserDetailsFromToken(getUserToken());
    const email = UserDetails.username;
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [appsMenuOpen, setAppsMenuOpen] = useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const dispatch = useDispatch()


    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleSubUserManagement = () => {
        navigate('/sub-users-management');
    };
    const handleAdminCenterUrl = () => {
        window.open(APP_DMP_ADMIN_CENTER_URL, '_blank', 'noopener,noreferrer');
    };

    const handleLogout = () => {
        sessionStorage.clear();
        localStorage.clear();
        dispatch(resetCalendar())
        dispatch(resetMetaSearch())
        dispatch(resetSearchAds())
        dispatch(resetPMax())
        dispatch(resetAccount())
        dispatch(resetEditBid())
        navigate("/");
    };

    const menuId = "primary-search-account-menu";
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            className={"custom-menu"}
        >
            {UserDetails.isAdminUser && (
                <MenuItem onClick={handleAdminCenterUrl}>Admin Center</MenuItem>
            )}

            {UserDetails.isMainUser && (
                <MenuItem onClick={handleSubUserManagement}>Users</MenuItem>
            )}

            <MenuItem className={"mt-4"} onClick={handleLogout}>
                Log Out
            </MenuItem>
            <MenuItem onClick={handleMenuClose} className={"user-email"}>
                {email}
            </MenuItem>
        </Menu>
    );

    const mobileMenuId = "primary-search-account-menu-mobile";
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="error">
                        <MailIcon/>
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                >
                    <Badge badgeContent={17} color="error">
                        <NotificationsIcon/>
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                >
                    <AccountCircle color="red"/>
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    const appsId = appsMenuOpen ? "simple-popover" : undefined;
    const handleAppsMenu = () => {
        setAppsMenuOpen(!appsMenuOpen);
    };

    const handleAppsMenuClose = () => {
        setAppsMenuOpen(null);
    };

    const getSplittedMenuItems = (menuItems) => {
        const totalItems = menuItems.length;
        const leftItemCount = Math.ceil(totalItems / 2) || 0;
        return (
            <div className={"split-container"}>
                <div className={"left-container"}>
                    {menuItems.slice(0, leftItemCount).map(({icon, text}) => (
                        <div className={"menu-item"}>
                            <img src={icon} className={"menu-icon"} alt={"menu icon"}/>{" "}
                            <span className={"menu-text"}>{text}</span>
                        </div>
                    ))}
                </div>
                <div className={"right-container"}>
                    {menuItems.slice(leftItemCount).map(({icon, text}) => (
                        <div className={"menu-item"}>
                            <img src={icon} className={"menu-icon"} alt={"menu icon"}/>{" "}
                            <span className={"menu-text"}>{text}</span>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <Box sx={{flexGrow: 1}} className={"navbar"}>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: "#61D5A9",

                    boxShadow: "none",
                    maxHeight: "40px",
                }}
            >
                <Toolbar sx={{maxHeight: "40px", minHeight: "40px !important"}}>
                    <Link
                        to="/transition"
                        style={{textDecoration: "none"}}
                    >
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{
                                display: {
                                    xs: "none",
                                    sm: "flex",
                                },
                                marginLeft: "-12px",
                            }}
                        >
                            <Typography
                                sx={{
                                    marginRight: "8px",
                                    fontSize: '16px',
                                    fontFamily: 'Quincy CF',
                                    fontWeight: '500',
                                    lineHeight: '18.13px',
                                    color: '#000000',
                                }}
                            >
                                DMP
                            </Typography>
                            <img src={LogoIcon} alt="Logo"/>
                        </Typography>
                    </Link>
                    <Box sx={{margin: "2px 0 0 106px"}}>
                        <AccountDropdown label={"Accounts"}/>
                    </Box>

                    <Box sx={{flexGrow: 1}}/>

                    <Box sx={{display: {xs: "none", md: "flex"}}}>
                        {/*<IconButton
                            size="small"
                            color="inherit"
                            aria-describedby={appsId}
                            id={{appsId}}
                            onClick={handleAppsMenu}
                            sx={{marginRight: "23px"}}>
                            <img src={AppsIcon} alt={"Apps"}/>
                        </IconButton>*/}
                        <IconButton
                            size="small"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                            sx={{marginRight: "-14px"}}
                        >
                            <IconWrapper icon={IconUser}/>
                        </IconButton>
                    </Box>
                    <Box sx={{display: {xs: "flex", md: "none"}}}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleAppsMenu}
                            color="inherit"
                        >
                            <img src={AppsIcon} alt={"Apps"}/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Toolbar sx={{maxHeight: "40px", minHeight: "40px !important"}}/>
            <Popover
                id={appsId}
                open={Boolean(appsMenuOpen)}
                anchorEl={appsMenuOpen}
                onClose={handleAppsMenuClose}
                className={"product-menu"}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
            >
                <div className={"apps-menu"}>
                    {PRODUCT_MENU_LIST.length
                        ? PRODUCT_MENU_LIST.map(
                            ({heading, className = "", menuItems}) => (
                                <div className={"menu-container"}>
                                    <div className={"menu-heading"}>{heading}</div>
                                    {className !== "split" ? (
                                        <div className={"menu-items"}>
                                            {menuItems?.length
                                                ? menuItems.map(({icon, text, url = ""}) => (
                                                    <a
                                                        className={"menu-item"}
                                                        target="_blank"
                                                        href={url}
                                                        rel="noreferrer"
                                                    >
                                                        <img
                                                            src={icon}
                                                            className={"menu-icon"}
                                                            alt={"menu icon"}
                                                        />{" "}
                                                        <span className={"menu-text"}>{text}</span>
                                                    </a>
                                                ))
                                                : ""}
                                        </div>
                                    ) : (
                                        getSplittedMenuItems(menuItems)
                                    )}
                                </div>
                            )
                        )
                        : ""}
                </div>
            </Popover>
            {renderMobileMenu}
            {renderMenu}
        </Box>
    );
}
