import React, {useEffect, useState} from 'react';
import 'react-sliding-side-panel/lib/index.css';
import './AlertSelectionForm.scss';
import AlertManager from "../AlertForm/AlertManager";
import SlidingPanel from "../AlertForm/SlidingPanel";
import Icons from "components/DmpIcons/icons";
import DmpIcons from "components/DmpIcons/icons";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {getUserToken} from "../../../utils/functions/userToken";
import { getUserDetailsFromToken } from 'utils/functions/userTokenDetails';

const CheckboxGroup = ({ title, options, onSelectionChange, onClosePlatform, selectedOption }) => {

    const handleCheckboxChange = (optionValue) => {
        if (selectedOption === optionValue) {
            onSelectionChange('');
        } else {
            onSelectionChange(optionValue);
            onClosePlatform();
        }
    };

    return (
        <div className="checkbox-group">
            <label className="group-title">{title}</label>
            <div className="checkbox-grid">
                {options.map((option) => (
                    <button
                        key={option.value}
                        className={`custom-button ${selectedOption === option.value ? 'selected-alert' : ''}`}
                        onClick={() => handleCheckboxChange(option.value)}
                        aria-pressed={selectedOption === option.value}
                    >
                        {option.label}
                    </button>
                ))}
            </div>
        </div>
    );
};

const AlertSelectionForm = ({ isOpen, onClose, onAlertTypeSelect, refreshData }) => {
    const [userDetails, setUserDetails] = useState({
        username: 'N/A',
        roles: 'N/A',
        isMainUser: false,
        isAdminUser: false,
        isSuperAdminUser: false
    });

    useEffect(() => {
        const token = getUserToken();
        const { UserDetails } = getUserDetailsFromToken(token);
        setUserDetails(UserDetails);
    }, []);
    const groupedAlertOptions = [
        {
            title: 'Performance Alerts',
            options: [
                { label: 'Google Hotel Ads', value: 'ALERT_METASEARCH_GOOGLE_HOTELS_ADS' },
                { label: 'Google Hotel Ads - Meta', value: 'ALERT_METASEARCH_GOOGLE_HOTELS_ADS_META' },
                { label: 'Google Hotel Ads - PPA', value: 'ALERT_METASEARCH_GOOGLE_HOTELS_ADS_PPA' },
                { label: 'Tripadvisor - Meta', value: 'ALERT_METASEARCH_TRIPADVISOR_META' },
                { label: 'Tripadvisor - SP', value: 'ALERT_METASEARCH_TRIPADVISOR_SP' },
                { label: 'Trivago - Meta', value: 'ALERT_METASEARCH_TRIVAGO_META' },
                { label: 'Trivago - SL', value: 'ALERT_METASEARCH_TRIVAGO_SL' },
                { label: 'Bing', value: 'ALERT_METASEARCH_BING' },
                { label: 'Kayak', value: 'ALERT_METASEARCH_KAYAK' },
                { label: 'Skyscanner', value: 'ALERT_METASEARCH_SKYSCANNER' },
                { label: 'Wego', value: 'ALERT_METASEARCH_WEGO_META' },
            ],
        },
        {
            title: 'Budget Alerts',
            options: [
                { label: 'Metasearch Budget', value: 'ALERT_METASEARCH_BUDGET' },
                { label: 'Google Search Ads Budget', value: 'ALERT_GOOGLE_SEARCH_ADS_BUDGET' },
            ],
        },
    ];

    if (userDetails.isAdminUser) {
        const performanceGroup = groupedAlertOptions.find(
            group => group.title === 'Performance Alerts'
        );
        if (performanceGroup) {
            performanceGroup.options.push({
                label: 'Display Ads',
                value: 'ALERT_DISPLAY_ADS'
            });
        }
    }

    const [selectedAlert, setSelectedAlert] = useState('');
    const [isPlatformVisible, setIsPlatformVisible] = useState(true);
    const [isFormVisible, setIsFormVisible] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleFormVisibility = () => {
        setIsFormVisible((prev) => !prev);
    };

    const headerStyle = {
        backgroundColor: !selectedAlert ? 'white' : '#61D5A9'
    };

    const togglePlatformSelection = () => {
        setIsPlatformVisible((prev) => !prev);
    };

    const selectedAlertLabel = groupedAlertOptions.flatMap(group => group.options)
        .find(option => option.value === selectedAlert)?.label + " Alert" || '';

    // Fonction pour réinitialiser les états
    const resetPanel = () => {
        setSelectedAlert('');
        setIsPlatformVisible(true);
        setIsFormVisible(true);
    };

    useEffect(() => {
        if (!isOpen) {
            resetPanel();
        }
    }, [isOpen]);

    const showSnackbar = (message, severity = 'success') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    return (
        <div>
        <SlidingPanel isOpen={isOpen} onClose={onClose}>
            <div className="alert-panel-container">
                <div className="platform-container" style={{ width: '100%' }}>
                    <div className="panel-header" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <div
                            onClick={onClose}
                            className="close-panel-circle"
                        >
                            <DmpIcons.ActionsIcons.ClosePanelFillColorIcon className="close-panel-icon" />
                        </div>
                        <h2 style={{
                            fontSize: '20px',
                            textAlign: 'left',
                            fontWeight: 400,
                            fontFamily: 'Quincy CF, serif',
                        }}>
                            New Alert
                        </h2>
                    </div>
                    <div className={`platform-selection ${isPlatformVisible ? 'expanded' : ''}`}>
                        <div className={`collapsible-header ${isPlatformVisible ? 'expanded' : ''} ${selectedAlert ? 'selected-alert' : ''} `} style={headerStyle} onClick={togglePlatformSelection}>
                            <h2 className="platform-title">1. SELECT ALERT TYPE</h2>
                            <span className="arrow-icon">
                                <img
                                    src={Icons.AccessibilityIconsSVG.ThinDownArrowIconSVG}
                                    alt="Arrow Down"
                                    className={isPlatformVisible ? 'rotate-up' : 'rotate-down'}
                                />
                              </span>
                        </div>
                        {isPlatformVisible && (
                            <div className="collapsible-section">
                                {groupedAlertOptions.map((group, index) => (
                                    <CheckboxGroup
                                        key={index}
                                        title={group.title}
                                        options={group.options}
                                        onSelectionChange={setSelectedAlert}
                                        onClosePlatform={togglePlatformSelection}
                                        selectedOption={selectedAlert}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                {selectedAlert && (
                    <div className="alert-form-container">
                        <div className={`platform-container ${isFormVisible ? 'expanded' : ''}`} >
                            <div className="collapsible-form">
                                <div className={`collapsible-header ${isFormVisible ? 'expanded' : ''}`} onClick={handleFormVisibility}>
                                    <h2 className="platform-title">2. CREATE | {selectedAlertLabel.toUpperCase()}</h2>
                                    <span className="arrow-icon">
                                        <img
                                            src={Icons.AccessibilityIconsSVG.ThinDownArrowIconSVG}
                                            alt="Arrow Down"
                                            className={isPlatformVisible ? 'rotate-up' : 'rotate-down'}
                                        />
                              </span>
                                </div>
                                {isFormVisible && (
                                    <AlertManager onClose={onClose}
                                                  alertData={{ alertType: selectedAlert, status: 'ENABLED' }}
                                                  showSnackbar={showSnackbar}
                                                  refreshData={refreshData}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </SlidingPanel>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );

};

export default AlertSelectionForm;
