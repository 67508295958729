import Layout from "../../components/Login/Layout";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import useAutoLogin from "./hooks/useAutoLogin";

const AutoLogin = () => {
    const navigate = useNavigate();
    const { isLoggedIn, loginError } = useAutoLogin();

    if (isLoggedIn) {
        navigate("/transition");
    }

    return (
        <Layout>
            <div className="box-2">
                {loginError ? (
                    <div style={{ color: "red", marginBottom: "20px" }}>
                        <p>{loginError}</p>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => navigate("/")}>
                            Go to Login Page
                        </Button>
                    </div>
                ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <CircularProgress size={24} style={{ marginRight: "10px", marginBottom: "20px" }} />
                        <div style={{ marginBottom: "20px" }}>
                            Trying to log in...
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default AutoLogin;