import React, {useMemo} from "react";
import DmpIcons from "components/DmpIcons/icons";
import SlidingPanelReact from 'react-sliding-side-panel';
import AlertTable from '../../alert-center/AlertTable/AlertTableDesigned';

const HistoryPanel =  ({ open, onClose, records, report_type, report_name }) => {
    const columnsFileHistory = useMemo(() => [
        { accessorKey: 'id', header: 'ID', maxSize: 40 },
        { 
          accessorKey: 'dateCreated', 
          header: 'Date Created', 
          maxSize: 220,
          Cell: ({ value }) => {
            const rawDate = value;
            if (!rawDate) return '-';
            const date = new Date(rawDate);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          }
        },
        {
          header: 'Download Report',
          accessorKey: 'downloadURL',
          Cell: ({ row, value }) => (
              value ? (
                  <a href={value} target="_blank" rel="noopener noreferrer">
                        {row?.remoteFileName || "Download"}
                  </a>
              ) : (
                  'N/A'
              )
          ),
          maxWidth: 220,
      },
    ], []);


  return (
    <SlidingPanelReact
        type="right"
        isOpen={open}
        size={ 50}
        backdropClicked={onClose}
    >
    <div className="panel-container">
      <div className="report-panel-container">
        <div className="platform-container" style={{ width: '100%' }}>
        <div className="panel-header" style={{ display: 'flex', justifyContent: 'flex-start'}}>
          <div  className="close-panel-circle"  onClick={() => { onClose(); }}  >
              <DmpIcons.ActionsIcons.ClosePanelFillColorIcon className="close-panel-icon" />
          </div>
          <h2 className='report-form-title'>
            File History - {report_name ?? 'No File Name'} - {report_type ?? 'Unknown Type'}
          </h2>
        </div>
        <div className="report-level-choice">
      <div className="hotel-table-container">
          <br />

          <AlertTable
              columns={columnsFileHistory}
              data={records}
            />

      </div>
    </div>
        </div>
      </div>
    </div>
      </SlidingPanelReact>
  );
};

export default HistoryPanel;