import "./keywordsReporting.scss";
import React, { useEffect, useRef, useState } from "react";
import DefaultLayout from "../../layout/defaultLayout";
import { Box, Chip, Snackbar, Alert } from "@mui/material";
import { DatePicker } from "components/DatePicker/index";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { updateGroupId, updateHotelId, updateLevel } from "store/slices/account/accountSlice";
import HotelsFilterMenu from "components/hotelFilterMenu";
import EditCampaignPanel from "features/google-search-ads-campaign/EditCampaignPanel";
import PageTitles from "../../components/PageTitles";
import KeywordsChartContainer from "features/searchads/keywordReporting/chartContainer";
import KeywordsTableContainer from "features/searchads/keywordReporting/TableContainer";
import HoverButton from "../../components/HoverButton";
import qs from 'query-string';
import { updateKeywordsReportingSegmentList, updateKeywordsReportingSelectedSegments } from "store/slices/searchAds/searchAds";
import { getDefaultSegments } from "utils/functions/getDefaultSegments";

const KeywordsReportingPage = () => {
  const searchParams = qs.parse(window.location.search)
  const {pathname, search} = useLocation()
  const platform = useSelector((state) => state?.searchAds?.campaign);
  const hotelId = useSelector((state) => state?.account?.hotel_id);
    const {
        account_id = "",
        "is_account_writable": isAccountWritable,
    } = useSelector((state) => state?.account?.account || {});
  const {hotelList = [], groupList = []} = useSelector(state => state?.account)
  const hotelButtonRef = useRef(null);
  const [openHotelModal, setOpenHotelModal] = useState(false);
  const [hotelFilters, setHotelFilters] = useState([]);
  const [selectedHotelOrGroupType, setSelectedHotelOrGroupType] =
    useState("hotels");
  const scrollContainerRef = useRef(null);
  const [isChartCollapsed, setIsChartCollapsed] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPanelOpen, setPanelOpen] = useState(false);
  const [editCampaignId, setEditCampaignId] = useState();
  const [,setCurrentAccountId] = useState(account_id);

  const handleCreateCampaign = () => {
    if (hotelFilters.length===1) {
      navigate(`/search-ads-campaign/create/${account_id}/${hotelId?.[0]}`);
    } else {
      setToastMessage("Please select one hotel.");
      setToastType("error");
      setShowToast(true);
    }
  };

  const togglePanel = () => {
    setPanelOpen((prev) => !prev);
  };

  useEffect(() => {
          if (account_id) {
            setCurrentAccountId((prev) => {
              if (prev !== account_id) {
                dispatch(updateGroupId([]));
              }
              return account_id;
            });
          } else {
            if (searchParams?.["hotelid"]) {
              dispatch(updateLevel("HOTEL"));
              if (typeof searchParams?.["hotelid"] === "string") {
                dispatch(updateHotelId([Number(searchParams?.["hotelid"])]));
              } else {
                dispatch(
                  updateHotelId(searchParams?.["hotelid"].map((h) => Number(h)))
                );
              }
            }
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [account_id, dispatch, search]);

  useEffect(() => {
    dispatch(
      updateLevel(selectedHotelOrGroupType === "hotels" ? "HOTEL" : "GROUP")
    );

    if (searchParams?.["hotelid"]) {
      dispatch(updateLevel("HOTEL"));
      if (typeof searchParams?.["hotelid"] === "string") {
        dispatch(updateHotelId([Number(searchParams?.["hotelid"])]));
        dispatch(
          updateKeywordsReportingSegmentList(
            getDefaultSegments([Number(searchParams?.["hotelid"])])
          )
        );
      } else {
        dispatch(
          updateHotelId(searchParams?.["hotelid"].map((h) => Number(h)))
        );
        dispatch(
          updateKeywordsReportingSegmentList(
            getDefaultSegments(searchParams?.["hotelid"].map((h) => Number(h)))
          )
        );
      }
    }
    dispatch(updateKeywordsReportingSelectedSegments(null));
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch,search,hotelList]);
  useEffect(() => {
      if(!searchParams?.['hotelid']){
        dispatch(updateKeywordsReportingSegmentList(getDefaultSegments(hotelId)))
      }
    },[dispatch,search,hotelId])

   useEffect(() => {
     return () => {
       dispatch(updateKeywordsReportingSelectedSegments(null));
     }; 
   }, [dispatch]);

  return (
    <div className="metasearch">
      <DefaultLayout page={isPanelOpen ? null : "SearchAdsPage"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: "18px",
          }}
        >
          <PageTitles pageTitle="Search Ads" pageSubTitle="Keywords" />
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "8px",
            }}
          >
              {isAccountWritable &&(
                  <HoverButton text="New Campaign" onClick={handleCreateCampaign}/>
              )}
              <DatePicker />
          </div>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingInline: 2,
          }}
        >
          <div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#000000",
                  fontWeight: "400",
                  marginTop: "4px",
                }}
              >
                <div
                  onClick={() => {
                    setOpenHotelModal(true);
                  }}
                  ref={hotelButtonRef}
                >
                  <Chip
                    label={
                      hotelId.length === 0
                        ? "Hotels"
                        : (selectedHotelOrGroupType === "hotels" &&
                            hotelId.length === hotelList.length) ||
                          (selectedHotelOrGroupType === "groups" &&
                            hotelId.length === groupList.length)
                        ? selectedHotelOrGroupType === "hotels"
                          ? "All Hotels"
                          : "All Groups"
                        : hotelId.length === 1
                        ? hotelList?.find(
                            (hotel) =>
                              hotel.hotel_id === hotelId?.[0]
                          )?.hotel_name
                        : `${hotelId.length} ${
                            selectedHotelOrGroupType === "hotels"
                              ? "hotels"
                              : "groups"
                          } selected`
                    }
                    sx={{
                      backgroundColor: "inherit",
                      fontFamily: "inter",
                      fontSize: "12px",
                      fontWeight: "400",
                      marginRight: "4px",
                      cursor: "pointer",
                      height: "26px",
                      minWidth:"120px",
                      border:"1px solid #BAB7B7",
                        ":hover": {
                            cursor: "pointer",
                            border: "1px solid #000000",
                        },
                    }}
                  />
                </div>
                {openHotelModal && (
                  <HotelsFilterMenu
                    allHotels={hotelList?.map((ho) =>
                      hotelId.find((hf) => hf === ho.hotel_id)
                        ? { ...ho, isSelected: true }
                        : { ...ho, isSelected: false }
                    )}
                    anchorEl={hotelButtonRef.current}
                    open={openHotelModal}
                    showGroups={false}
                    isSelectAllRequired
                    onApply={(selected) => {
                        setHotelFilters(selected);
                        if (selectedHotelOrGroupType === "hotels") {
                          if (selected.length === 1) {
                            dispatch(updateKeywordsReportingSegmentList(["CAMPAIGN"]));
                          } else {
                            dispatch(updateKeywordsReportingSegmentList(["HOTELS"]));
                          }
                          dispatch(updateKeywordsReportingSelectedSegments(null))
                          dispatch(
                            updateHotelId(
                              selected.map((item) => item?.["hotel_id"])
                            )
                          );
                        } else {
                          dispatch(
                            updateGroupId(selected.map((item) => item?.["id"]))
                          );
                        }
                        navigate(`${pathname}?${createSearchParams({hotelid: selected?.map(p => p.hotel_id)})}`)
                    }}
                    onClose={() => {
                      setOpenHotelModal(false);
                    }}
                    setSelectedHotelOrGroupType={(type) => {
                      setSelectedHotelOrGroupType(type);
                      dispatch(
                        updateLevel(type === "hotels" ? "HOTEL" : "GROUP")
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </Box>
        <Box sx={{}} ref={scrollContainerRef}>
          <div
            style={{
              display: isChartCollapsed ? "none" : "block",
            }}
          >
            <KeywordsChartContainer backgroundColor="transparent" />
          </div>
          <KeywordsTableContainer
            isChartCollapsed={isChartCollapsed}
            setIsChartCollapsed={setIsChartCollapsed}
            isAccountWritable={isAccountWritable}
            onEditClick={() => {
              if (platform?.length > 1) {
                setToastMessage("Please select single platform");
                setToastType("error");
                setShowToast(true);
              }
            }}
            onEditCampaign={(id) => {
              setEditCampaignId(id);
              setPanelOpen(true);
            }}
          />
        </Box>{" "}
        <EditCampaignPanel campaignId={editCampaignId} isOpen={isPanelOpen} onClose={togglePanel} />
      </DefaultLayout>
      <Snackbar
        // anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showToast}
        autoHideDuration={3000}
        onClose={() => {
          setShowToast(false);
        }}
      >
        <Alert severity={toastType} variant="filled" sx={{ width: "100%" }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default KeywordsReportingPage;
