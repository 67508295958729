export const BIDDING_STRATEGIES = {
    MAXIMIZE_CONVERSIONS: "Maximize Conversions",
    MAXIMIZE_CONVERSION_VALUE: "Maximize Conversion value",
    MAXIMIZE_CLICKS: "Maximize Clicks",
    MANUAL_CPC: "Manual CPC",
    TARGET_IMPRESSION_SHARE: "Maximize Impression Share",
};

export const BIDDING_STRATEGIES_CREATOR = {
    MAXIMIZE_CONVERSIONS: "Maximize Conversions",
    MAXIMIZE_CONVERSION_VALUE: "Maximize Conversion value",
    MAXIMIZE_CLICKS: "Maximize Clicks",
    MANUAL_CPC: "Manual CPC",
    TARGET_IMPRESSION_SHARE: "Maximize Impression Share",
};
