import React, {useEffect, useState} from "react";
import {Box, Grid, IconButton, InputAdornment, Typography,} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";

const Section = ({
                     title,
                     items,
                     addItem = false,
                     handleInputChange,
                     handleRemove = false,
                     maxLengthInput = null,
                     isEdit = false,
                     onBlur,
                     status = {},
                 }) => {
    const [isAdded, setIsAdded] = useState(false);

    useEffect(() => {
        setIsAdded(items.length > 0);
    }, [items.length]);

    const handleAddClick = () => {
        addItem();
    };

    return (
        <Grid item md={12} xs={isEdit ? 12 : 6} spacing={2}>
            <Box className={title.toLowerCase().replace(/ /g, "-")} mt={4}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    className="header-box"
                >
                    <Typography variant="subtitle1">{title}</Typography>
                    <Box display="flex" alignItems="center">
                        {isAdded && addItem !== false && (
                            <IconButton
                                onClick={addItem}
                                color="primary"
                                className="add-button"
                                sx={{paddingBottom: "1px"}}
                            >
                                <AddIcon />
                            </IconButton>
                        )}
                    </Box>
                </Box>

                <Box className="content-box" mt={2}>
                    {!isAdded && addItem !== false ? (
                        <Box
                            display="flex"
                            alignItems="center"
                            gap={0}
                            onClick={handleAddClick}
                            style={{ cursor: "pointer", color: "#61D5A9" }}
                        >
                            <IconButton
                                color="primary"
                                className="add-button-text"
                                style={{ fontSize: "10px" }}
                            >
                                <AddIcon />
                            </IconButton>
                            <Typography
                                style={{
                                    textTransform: "uppercase",
                                    color: "#61D5A9",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                }}
                            >
                                Add {title}
                            </Typography>
                        </Box>
                    ) : (
                        items.map((item, index) => (
                            <Box
                                key={item.id}
                                display="flex"
                                alignItems="center"
                                gap={1}
                                className="input-container"
                            >
                                <Box display="flex" alignItems="center" flexGrow={1}>
                                    <CustomOutlinedInput
                                        placeholder={`${title} ${index + 1}`}
                                        className="no-border-focus"
                                        fullWidth
                                        value={item.text}
                                        onChange={(e) =>
                                            handleInputChange(item.id, "text", e.target.value,index)
                                        }
                                        onBlur={() => onBlur(item.id, item.text)}
                                        inputProps={{ maxLength: maxLengthInput || undefined }}
                                        InputProps={{
                                            endAdornment: maxLengthInput && (
                                                <InputAdornment position="end">
                          <span style={{ fontSize: "12px" }}>
                            {`${item.text.length}/${maxLengthInput}`}
                          </span>
                                                </InputAdornment>
                                            ),
                                        }}
                                        helperText={
                                            status[item.id]?.error ||
                                            `${title.charAt(0).toUpperCase()}${title
                                                .slice(1)
                                                .toLowerCase()} ${index + 1}`
                                        }
                                        error={status[item.id] && status[item.id].success === false}
                                        success={status[item.id] && status[item.id].success === true}
                                    />
                                </Box>
                                {handleRemove !== false ? (
                                    <IconButton
                                        onClick={() => handleRemove(item.id)}
                                        color="primary"
                                        className="remove-button"
                                        style={{
                                            visibility: item.irremovable ? "hidden" : "visible",
                                            marginTop: "-28px",
                                        }}
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                ) : (
                                    <Box width="28px" height="28px" />
                                )}
                            </Box>
                        ))
                    )}
                </Box>
            </Box>
        </Grid>
    );
};

export default Section;
