import axios from "axios";
import {getUserToken} from "../../../../../utils/functions/userToken";
import {APP_GOOGLE_SEM_API_ENDPOINT} from "../../../../../constants/api_urls";

export const getKeywordSuggestions = async (idAccount, idHotel) => {
    const jwtToken = getUserToken();
    try {
        const response = await axios.post(`${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/keywords/suggestion`,
            {
                id_account: idAccount,
                id_hotel: idHotel
            },
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                }
            }
        );

        // Ensure the response contains the expected data structure
        if (!response.data?.keywords || !Array.isArray(response.data.keywords)) {
            console.error("Invalid response format:", response.data);
            return [];
        }

        // Format the keywords
        return response.data.keywords.map(({ keywordText, matchType }) => ({
            keyword: keywordText.trim(), // Trim spaces
            matchType
        }));
    } catch (error) {
        console.error("Error fetching keyword suggestions:", error);
        return []; // Return an empty array in case of an error
    }
};
