import React, {useEffect, useState} from "react";
import {Box, CircularProgress, Drawer, IconButton, Switch, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {initialMonthlyBudget} from "../googlePmaxFormComponent/Utils/structures/initialState";
import GooglePMaxCampaignEditor from "../GooglePMaxCampaignEditor";
import './GooglePMaxSlideOutPanel.scss';
import {assignDetails} from "../googlePmaxFormComponent/Utils/assignDetails";
import {createCampaignSettingsPayload, saveCampaignSettings} from "./Utils/campaignSettingsApi";
import {createAdsCreativePayload, saveAdsCreative} from "./Utils/campaignAdsCreativeApi";
import {updateCampaignStatus} from "./Utils/updateCampaignStatus";
import {importGetCampaignAssetsListDetails} from "../googlePmaxFormComponent/Utils/getCampaignListDetails";

const GooglePMaxSlideOutPanel = ({ isOpen, onClose, idAccount, idHotel, idCampaign, triggerAlert }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [expandedPanel, setExpandedPanel] = useState("settings");
    const [visitedPanels, setVisitedPanels] = useState(["settings"]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [targetValue, setTargetValue] = useState("");
    const [biddingStrategy, setBiddingStrategy] = useState("");
    const [campaignName, setCampaignName] = useState("");
    const [targetCpa, setTargetCpa] = useState("");
    const [targetRoas, setTargetRoas] = useState("");
    const [monthlyBudget, setMonthlyBudget] = useState(initialMonthlyBudget);
    const [longHeadlines, setLongHeadlines] = useState([]);
    const [headlines, setHeadlines] = useState([]);
    const [descriptions, setDescriptions] = useState([]);
    const [finalURL, setFinalURL] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [landscape, setLandscape] = useState([]);
    const [square, setSquare] = useState([]);
    const [portrait, setPortrait] = useState([]);
    const [logo, setLogo] = useState([]);
    const [landscapeLogo, setLandscapeLogo] = useState([]);
    const [isCampaignPaused, setIsCampaignPaused] = useState(false);
    const [errors, setErrors] = useState({});
    const [currencySymbol, setCurrencySymbol] = useState('€');
    const [campaignList, setCampaignList] = useState([]);

    const handleToggle = async () => {
        const newStatus = isCampaignPaused ? "ENABLED" : "PAUSED";
        setDrawerOpen(true);

        try {
            await updateCampaignStatus({ campaign_id: idCampaign, campaign_status: newStatus });
            setIsCampaignPaused((prev) => !prev);
            triggerAlert("success", `Campaign ${newStatus.toLowerCase()} successfully!`);
        } catch (error) {
            triggerAlert("error", "Failed to update campaign status. Please try again.");
        } finally {
            setDrawerOpen(false);
        }
    };

    const fetchImportGetCampaignAssetsListDetails = async () => {
        const campaignDetails = await importGetCampaignAssetsListDetails(idHotel, idCampaign);
        if (campaignDetails.success == true) {
            setCampaignList(campaignDetails.data)
        }
    };

    useEffect(() => {
        if (idCampaign) {
            assignDetails({
                isOpen,
                idCampaign,
                setDrawerOpen,
                setCampaignName,
                setBiddingStrategy,
                setTargetCpa,
                setTargetRoas,
                setTargetValue,
                setMonthlyBudget,
                setSelectedLanguage,
                setSelectedCountries,
                setFinalURL,
                setBusinessName,
                setLongHeadlines,
                setHeadlines,
                setDescriptions,
                setIsCampaignPaused,
                setLandscape,
                setSquare,
                setPortrait,
                setLogo,
                setLandscapeLogo,
                setCurrencySymbol
            });
            fetchImportGetCampaignAssetsListDetails();
            setErrors({})
        }
    }, [isOpen, idCampaign]);

    const onSaveSettings = async () => {
        setDrawerOpen(true);
        const payload = createCampaignSettingsPayload({
            idCampaign,
            campaignName,
            biddingStrategy,
            targetCpa,
            targetRoas,
            targetValue,
            monthlyBudget,
            selectedLanguage,
            selectedCountries,
        });

        try {
            await saveCampaignSettings(payload);
            triggerAlert("success", "Campaign settings saved successfully !");
        } catch (error) {
            setErrors(error.errors);
            triggerAlert("error", "Error while saving campaign settings, please retry later.");
        } finally {
            setDrawerOpen(false);
        }
    };

    const onSaveAdsCreative = async () => {
        setDrawerOpen(true);

        const payload = createAdsCreativePayload({
            campaignId: idCampaign,
            finalURL,
            businessName,
            headlines,
            descriptions,
            longHeadlines,
            marketingImages: landscape,
            squareMarketingImages: square,
            portraitMarketingImages: portrait,
            logos: logo,
            landscapeLogos: landscapeLogo,
        });

        try {
            await saveAdsCreative(payload);
            triggerAlert("success", "Ads creative saved successfully!");
        } catch (error) {
            setErrors(error.errors);
            triggerAlert("error", "Failed to save ads creative. Please try again later.");
        } finally {
            setDrawerOpen(false);
        }
    };

    const handleNext = (currentPanel) => {
        if (currentPanel === "settings") {
            setExpandedPanel("adsCreative");
            setVisitedPanels((prev) => [...prev, "adsCreative"]);
        }
    };

    return (
        <>
        {isOpen && !drawerOpen && (
            <Box
                sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 1100,
                }}
            />
        )}

    {/* Panneau latéral */}
    <Box className={`GooglePMaxSlideOutPanel ${isOpen ? "open" : ""}`}>
        <Box className="header" style={{ display: "flex", justifyContent: "space-between" }}>
            <Box style={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={onClose} className="closeButton">
                    <CloseIcon fontSize="small" />
                </IconButton>
                <Typography variant="body1" className="title">Edit Campaign</Typography>
            </Box>
            <Box style={{ display: "flex", alignItems: "center" }}>
                <Switch
                    checked={isCampaignPaused}
                    onChange={handleToggle}
                    color="primary"
                    inputProps={{ "aria-label": "Pause Campaign Toggle" }}
                />
                <Typography variant="body2" className="pause-toggle-title">Pause Campaign</Typography>
            </Box>
        </Box>

        <Box className="content">
            <GooglePMaxCampaignEditor
                expandedPanel={expandedPanel}
                setExpandedPanel={setExpandedPanel}
                visitedPanels={visitedPanels}
                handleNext={handleNext}
                selectedCountries={selectedCountries}
                setSelectedCountries={setSelectedCountries}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                targetValue={targetValue}
                setTargetValue={setTargetValue}
                biddingStrategy={biddingStrategy}
                setBiddingStrategy={setBiddingStrategy}
                campaignName={campaignName}
                setCampaignName={setCampaignName}
                targetCpa={targetCpa}
                setTargetCpa={setTargetCpa}
                targetRoas={targetRoas}
                setTargetRoas={setTargetRoas}
                monthlyBudget={monthlyBudget}
                setMonthlyBudget={setMonthlyBudget}
                headlines={headlines}
                setHeadlines={setHeadlines}
                descriptions={descriptions}
                setDescriptions={setDescriptions}
                finalURL={finalURL}
                setFinalURL={setFinalURL}
                businessName={businessName}
                setBusinessName={setBusinessName}
                longHeadlines={longHeadlines}
                setLongHeadlines={setLongHeadlines}
                isEdit={true}
                landscape={landscape}
                setLandscape={setLandscape}
                square={square}
                setSquare={setSquare}
                portrait={portrait}
                setPortrait={setPortrait}
                logo={logo}
                setLogo={setLogo}
                landscapeLogo={landscapeLogo}
                setLandscapeLogo={setLandscapeLogo}
                idAccount={idAccount}
                idHotel={idHotel}
                onSaveSettings={onSaveSettings}
                onSaveAdsCreative={onSaveAdsCreative}
                setDrawerOpen={setDrawerOpen}
                idCampaign={idCampaign}
                errors={errors}
                currencySymbol={currencySymbol}
                campaignList={campaignList}
            />
        </Box>
        <Drawer
            anchor="top"
            open={drawerOpen}
            PaperProps={{
                sx: {
                    background: "transparent"
                },
            }}
        >
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <CircularProgress size={60} color="primary" />
            </Box>
        </Drawer>
    </Box>
</>
    );
};

export default GooglePMaxSlideOutPanel;
