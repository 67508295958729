import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Builder, Query, Utils as QbUtils} from '@react-awesome-query-builder/ui';
import TextField from '@mui/material/TextField';
import {sqlToJsonLogic, transformFieldNames, buildFieldMap} from '../../Functions/index';
import '../AlertForm.scss';
import Tags from "@yaireo/tagify/dist/react.tagify";
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import '@react-awesome-query-builder/ui/css/styles.css';
import '@react-awesome-query-builder/ui/css/compact_styles.css';
import CustomFormControl from "../../Dropdown/CustomFormControl";
import {Box, FormControlLabel, FormGroup} from "@mui/material";
import ListSubheader from '@mui/material/ListSubheader';
import QueryBuilderConfig from "./QueryBuilderConfig";
import CustomButtonWithIcon from "../../../../components/CustomButtonWithIcon/Buttons";

const AlertBaseForm = ({ apiEndpoint, alertType, jwtToken, submitCallback, alertData, availableSegments, onClose, showSnackbar }) => {
    const [alertName, setAlertName] = useState(alertData?.name || '');
    const [segmentList, setSegmentList] = useState(alertData?.segmentList || []);
    const [alertRecipients, setAlertalertRecipients] = useState(alertData?.recipients || []);
    const [additionnalColumns, setAdditionnalColumns] = useState(alertData?.additionnalColumns || []);
    const [alertPeriod, setAlertPeriod] = useState(alertData?.alertPeriod || 1);
    const [alertCondition, setAlertCondition] = useState(alertData?.alertCondition || null);
    const [tree, setTree] = useState(null);
    const [optionsForAdditionalColumns, setOptionsForAdditionalColumns] = useState([]);
    const tagifyRef = useRef(null);
    const [fieldMap, setFieldMap] = useState({});

    const [errors, setErrors] = useState({
        alertName: false,
        alertPeriod: false,
        alertRecipients: false,
        alertCondition: false,
        additionnalColumns: false,
    });

    const [config, setConfig] = useState(QueryBuilderConfig);



    useEffect(() => {
        if (alertData) {
            setAlertName(alertData.name || '');
            setSegmentList(alertData.segmentList || []);
            setAlertalertRecipients(alertData.recipients || []);
            setAlertCondition(alertData.alertCondition || null);
            setAdditionnalColumns(alertData.additionnalColumns || '');
            setAlertPeriod(alertData.alertPeriod || '');
        }
    }, [alertData]);

    const handleSegmentChange = (e) => {
        const value = e.target.value;
        if (segmentList.includes(value)) {
            setSegmentList(segmentList.filter(v => v !== value));
        } else {
            setSegmentList([...segmentList, value]);
        }
    };

    useEffect(() => {
        if (alertCondition && config.fields && Object.keys(config.fields).length > 0) {
            const rawLogic = sqlToJsonLogic(alertCondition);
            // Transforme "totalSpentPercentEvo" -> "Metrics Evolution.totalSpentPercentEvo"
            const transformedLogic = transformFieldNames(rawLogic, fieldMap);

            const generatedTree = QbUtils.loadFromJsonLogic(transformedLogic, config);
            setTree(generatedTree);
        } else {
            const emptyTree = QbUtils.checkTree(
                QbUtils.loadTree({id: QbUtils.uuid(), type: 'group'}),
                config
            );
            setTree(emptyTree);
        }
    }, [alertCondition, config, fieldMap]);


    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const response = await fetch(apiEndpoint, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json',
                    }
                });
                const result = await response.json();
                const fields = generateFieldsFromMetrics(result.metric_name || []);
                setConfig(prevConfig => ({
                    ...QueryBuilderConfig,
                    fields: fields,
                    settings: {
                        ...prevConfig.settings,
                        showNot: false,
                        canReorder: false,
                        groupOperators: ['AND', 'OR'],
                        forceShowConj: true,
                        //needed or we retrieve for ex "Metrics Evolution.totalSpentPercentEvo" instead of "totalSpentPercentEvo" due to the level on the field
                        formatField: (field) => {
                            // On ne garde que le dernier segment après le "."
                            return field.split(".").pop();
                        },
                    },
                }));
                const newFieldMap = buildFieldMap(fields);
                setFieldMap(newFieldMap);

                setOptionsForAdditionalColumns(result.metric_name || []);
            } catch (error) {
            }
        };

        fetchMetrics();
    }, [apiEndpoint, jwtToken]);



    const generateFieldsFromMetrics = (metrics) => {
        //Group the metrics by their metric_group property
        const groupedMetrics = metrics.reduce((acc, metric) => {
            const { metric_group } = metric;
            if (!acc[metric_group]) {
                acc[metric_group] = [];
            }
            acc[metric_group].push(metric);
            return acc;
        }, {});

        const fields = {};
        Object.keys(groupedMetrics).forEach((groupName) => {
            // For each grp, we prepare a subfields object to store individual metrics
            const subfields = {};

            // sublfield creation
            groupedMetrics[groupName].forEach((metric) => {
                const { metric_label, metric_column } = metric;
                subfields[metric_column] = {
                    fieldSettings: {
                        groupTitle: groupName,
                    },
                    label: metric_label,
                    type: 'number',
                    valueSources: ['value'],
                    preferWidgets: ['number'],
                    operators: [
                        'equal',
                        'less',
                        'greater',
                        'less_or_equal',
                        'greater_or_equal',
                    ],
                    widgets: {
                        number: {
                            operators: [
                                'equal',
                                'less',
                                'greater',
                                'less_or_equal',
                                'greater_or_equal',
                            ],
                        },
                    },
                };
            });

            // structure for each group
            fields[groupName] = {
                type: '!struct',
                label: groupName,
                subfields,
            };
        });

        return fields;
    };

    const handleRecipientsChange = useCallback((e) => {
        let parsedValue;
        try {
            parsedValue = JSON.parse(e.detail.value);
        } catch (error) {
            parsedValue = [];
        }

        const updatedRecipients = parsedValue.map(tag => tag.value);
        setAlertalertRecipients(updatedRecipients);
    }, []);

    const handleSubmit = async () => {
        const sqlFormat = QbUtils.sqlFormat(tree, config);
        const body = {
            alert_name: alertName,
            segment_list: segmentList,
            alert_recipients: alertRecipients,
            additionnal_columns: (additionnalColumns && additionnalColumns.length > 0)
                ? additionnalColumns.map(column => column)
                : [],
            alert_condition: sqlFormat,
            alert_period: alertPeriod
        };

        // Vérifiez les champs requis
        const newErrors = {
            alertName: !body.alert_name,
            alertPeriod: !body.alert_period,
            alertRecipients: body.alert_recipients.length === 0,
            alertCondition: !body.alert_condition,
            additionnalColumns: body.additionnal_columns.length === 0,
        };

        setErrors(newErrors);

        // Vérifiez s'il y a des erreurs
        const hasErrors = Object.values(newErrors).some(error => error);

        if (hasErrors) {
            showSnackbar('Please fill in all required fields', 'error');
        } else {
            submitCallback(body);
        }
    };


    const onChange = (immutableTree, config) => {
        setTree(immutableTree);
    };

    return (
        <div className="alert-form">
            <div className="top-side">
                <TextField
                    placeholder="Alert Name:"
                    variant="standard"
                    value={alertName}
                    required
                    error={errors.alertName}
                    helperText={errors.alertName ? "This field is required" : ""}
                    onChange={(e) => setAlertName(e.target.value)}
                    sx={{
                        '& ::placeholder': { fontSize: '12px' },
                        '& .MuiInputBase-input': {
                            fontSize: '12px',
                        },
                        width: "55%"
                        }}
                />
            </div>

            <div className="bottom-top-side">
                <div className="alert-period-container">
                <label>ALERT PERIOD</label>
                    <CustomFormControl
                        value={alertPeriod}
                        onChange={(e) => setAlertPeriod(e.target.value)}
                        displayEmpty
                        renderValue={(selected) => {
                            if (!selected) {
                                return (
                                    <span style={{
                                        fontSize: "12px",
                                        color: "#bab7b7"
                                    }}>Number of Days</span>
                                );
                            }
                            if (selected === -1) {
                                return "Current Month";
                            }
                            return selected+ " day(s)";
                        }}
                        required
                        error={errors.alertPeriod}
                        helperText={errors.alertPeriod ? "This field is required" : ""}
                    >
                        <MenuItem disabled value="">
                            <span style={{
                                fontSize: "12px",
                                color: "black",
                                fontFamily: "Inter",
                                marginLeft: "4px"
                            }}>Number of Days</span>
                        </MenuItem>


                        {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
                            <MenuItem key={num} value={num} sx={{ fontSize: '12px', fontFamily: "Inter", color: "black" }}>
                                {num}
                            </MenuItem>
                        ))}
                        <MenuItem key={-1} value={-1} sx={{ fontSize: '12px', fontFamily: "Inter", color: "black" }}>
                            Current Month
                        </MenuItem>
                    </CustomFormControl>


                </div>
                {availableSegments && availableSegments.length > 0 && (
                    <div className="segment-list-container">
                        <div className="segment-list-label">
                            <label>VIEW BY</label>
                        </div>
                        <div className="segment-list-checkboxes">
                            <FormGroup>
                                {availableSegments.includes('DEVICE') && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="small"
                                                sx={{
                                                    paddingBlock: "2px",
                                                    color: "#BAB7B7",
                                                    "&.Mui-checked": {
                                                        color: "#61D5A9",
                                                    },
                                                    "& .MuiSvgIcon-root": {
                                                        height: "12px",
                                                        width: "12px",
                                                    },
                                                }}
                                                onChange={handleSegmentChange}
                                                checked={segmentList?.includes("DEVICE") || false}
                                                value={"DEVICE"}
                                            />
                                        }
                                        sx={{
                                            alignItems: "flex-start",
                                            "& .MuiFormControlLabel-label": {
                                                color: "black",
                                                fontSize: "12px",
                                                fontFamily: "Inter",
                                                fontWeight: 400,
                                                marginLeft: "8px"
                                            },
                                        }}
                                        label="Device"
                                        className="custom-checkbox-label"
                                    />
                                )}
                                {availableSegments.includes('MARKET') && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="small"
                                                sx={{
                                                    paddingBlock: "2px",
                                                    color: "#BAB7B7",
                                                    "&.Mui-checked": {
                                                        color: "#61D5A9",
                                                    },
                                                    "& .MuiSvgIcon-root": {
                                                        height: "12px",
                                                        width: "12px",
                                                    },
                                                }}
                                                onChange={handleSegmentChange}
                                                checked={segmentList?.includes("MARKET") || false}
                                                value={"MARKET"}
                                            />
                                        }
                                        sx={{
                                            alignItems: "flex-start",
                                            "& .MuiFormControlLabel-label": {
                                                color: "black",
                                                fontSize: "12px",
                                                fontFamily: "Inter",
                                                fontWeight: 400,
                                                marginLeft: "8px"
                                            },
                                        }}
                                        label="Market"
                                        className="custom-checkbox-label"
                                    />
                                )}
                            </FormGroup>
                        </div>
                    </div>

                )}

                <div className="additional-columns-container">
                    <label>ADDITIONAL COLUMNS</label>
                    <CustomFormControl
                        multiple
                        value={additionnalColumns || []}
                        onChange={(event) => setAdditionnalColumns(event.target.value)}
                        displayEmpty
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return (
                                    <span style={{ fontSize: "12px", fontFamily: "Inter", color: "black" }}>
                    Choose Columns
                </span>
                                );
                            }
                            return selected
                                .map((value) => {
                                    const option = optionsForAdditionalColumns.find(
                                        (opt) => opt.metric_name === value
                                    );
                                    return option ? option.metric_label : value;
                                })
                                .join(", ");
                        }}
                        required
                        error={errors.additionnalColumns}
                        helperText={errors.additionnalColumns ? "This field is required" : ""}
                        size="small"
                        sx={{ width: "280px" }}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                    >
                        <ListSubheader
                            style={{
                                fontSize: "12px",
                                fontFamily: "Inter",
                                color: "#6A76DB",
                                lineHeight: '20px',
                            }}
                        >
                            Basic Metrics
                        </ListSubheader>
                        {optionsForAdditionalColumns
                            .filter(option => option.metric_group === "Metrics")
                            .map((option) => (
                                <MenuItem
                                    key={option.metric_name}
                                    value={option.metric_name}
                                    sx={{ fontSize: "12px", color: "black", fontFamily: "Inter" }}
                                >
                                    <Checkbox
                                        checked={additionnalColumns.includes(option.metric_name)}
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: 12,
                                            },
                                        }}
                                    />
                                    <ListItemText
                                        primary={option.metric_label}
                                        primaryTypographyProps={{
                                            style: { fontSize: "12px", fontFamily: "Inter", color: "black" },
                                        }}
                                    />
                                </MenuItem>
                            ))}
                        <ListSubheader
                            style={{
                                fontSize: "12px",
                                fontFamily: "Inter",
                                color: "#6A76DB",
                                lineHeight: '20px',
                            }}
                        >
                            Evolution Metrics
                        </ListSubheader>
                        {optionsForAdditionalColumns
                            .filter(option => option.metric_group === "Metrics Evolution")
                            .map((option) => (
                                <MenuItem
                                    key={option.metric_name}
                                    value={option.metric_name}
                                    sx={{ fontSize: "12px", color: "black", fontFamily: "Inter" }}
                                >
                                    <Checkbox
                                        checked={additionnalColumns.includes(option.metric_name)}
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: 12,
                                            },
                                        }}
                                    />
                                    <ListItemText
                                        primary={option.metric_label}
                                        primaryTypographyProps={{
                                            style: { fontSize: "12px", fontFamily: "Inter", color: "black" },
                                        }}
                                    />
                                </MenuItem>
                            ))}
                    </CustomFormControl>


                </div>

            </div>

            <div className="query-builder-container">
                <label>METRIC ALERT RULES *</label>
                <div className="query-builder">
                {tree && (
                    <Query
                        {...config}
                        value={tree || null}
                        onChange={onChange}
                        renderBuilder={(props) => <Builder {...props} />}
                    />
                )}
                </div>
            </div>


            <div className="bottom-side">
                <label>RECIPIENTS *</label>
                <div className="email-container">
                    <Box sx={{ display: "flex", gap: "3px", flexDirection: "column" }}>
                        <Tags
                            ref={tagifyRef}
                            whitelist={alertRecipients}
                            placeholder='Recipients'
                            settings={{
                                pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            }}
                            onChange={handleRecipientsChange}
                            defaultValue={Array.isArray(alertRecipients) ? alertRecipients.join(',') : ""}
                            className={errors.alertRecipients ? 'error-tagify' : ''}
                        />
                        {errors.alertRecipients && <span style={{ color: '#d32f2f', fontSize: '12px' }}>This field is required</span>}
                    </Box>
                </div>
            </div>

            <div className="button-container">
                <CustomButtonWithIcon
                    color="#b0acac"
                    hoverColor="#12794F"
                    titleColor="#373F41"
                    hoverTitleColor={"white"}
                    width={"120px"}
                    height={"24px"}
                    borderRadius="20px"
                    textStyle={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '14.52px',
                        textAlign: 'center',
                    }}
                    onClick={handleSubmit}

                >
                    Save Alert
                </CustomButtonWithIcon>

                <CustomButtonWithIcon
                    color="#b0acac"
                    hoverColor="#12794F"
                    titleColor="#373F41"
                    hoverTitleColor={"white"}
                    width={"120px"}
                    height={"24px"}
                    borderRadius="20px"
                    textStyle={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '14.52px',
                        textAlign: 'center',
                    }}
                    onClick={onClose}

                >
                    Cancel
                </CustomButtonWithIcon>
            </div>
        </div>
    );
};

export default AlertBaseForm;
