import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem} from "@mui/material";
import CustomFormControl from "../../../../../alert-center/Dropdown/CustomFormControl";

const CampaignImportDialog = ({ open, onClose, campaignList, onConfirm }) => {
    const [selectedCampaign, setSelectedCampaign] = useState("");
    const handleImportClick = () => {
        if (selectedCampaign) {
            onConfirm(selectedCampaign);
        }
    };

    return <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Select a Campaign to Import</DialogTitle>
        <DialogContent>
            <CustomFormControl
                value={selectedCampaign}
                onChange={(e) => setSelectedCampaign(e.target.value)}
                displayEmpty
            >
                <MenuItem value="" disabled>
                    <span style={{
                        fontSize: "12px",
                        color: "#bab7b7"
                    }}>Select Campaign</span>
                </MenuItem>
                {campaignList.map((campaign) => {
                    return (
                        <MenuItem key={campaign.campaign_id} value={campaign.campaign_id}>
                            <span style={{
                                fontSize: "12px",
                                color: "#373F41"
                            }}>{campaign.campaign_settings?.campaign_name ?? `Campaign ID : ${campaign.campaign_id}`}</span>
                        </MenuItem>
                    );
                })}
            </CustomFormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="secondary">
                Cancel
            </Button>
            <Button
                onClick={handleImportClick}
                color="primary"
                variant="contained"
                disabled={!selectedCampaign}
            >
                Import
            </Button>
        </DialogActions>
    </Dialog>;
};

export default CampaignImportDialog;