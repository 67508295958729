import {APP_GLOBAL_REPORTING_API_ENDPOINT} from "../../../../constants/api_urls";
import axios from "axios";
import {getUserToken} from "../../../../utils/functions/userToken";

/**
 * Parses API data into formatted KPI metrics.
 *
 * @param {Object} apiData - The data returned from the API.
 * @param {string} [apiData.cost_kpi] - The cost KPI value.
 * @param {string} [apiData.cost_kpi_compare] - The comparison percentage for cost KPI.
 * @param {string} [apiData.generated_kpi] - The generated value KPI.
 * @param {string} [apiData.generated_kpi_compare] - The comparison percentage for generated KPI.
 * @param {string} [apiData.clicks_kpi] - The clicks KPI value.
 * @param {string} [apiData.clicks_kpi_compare] - The comparison percentage for clicks KPI.
 * @param {string} [apiData.cpc_kpi] - The cost-per-click KPI value.
 * @param {string} [apiData.cpc_kpi_compare] - The comparison percentage for CPC KPI.
 * @param {string} [apiData.conversion_kpi] - The conversions KPI value.
 * @param {string} [apiData.conversion_kpi_compare] - The comparison percentage for conversions KPI.
 * @param {string} [apiData.roas_kpi] - The return on ad spend KPI value.
 * @param {string} [apiData.roas_kpi_compare] - The comparison percentage for ROAS KPI.
 * @param {string} [apiData.currency_symbol] - Currency Symbol
 *
 * @returns {Array<Object>} An array of formatted KPI metrics.
 */
const parseMetrics = (apiData) => {
    const getDirection = (value) => {
        if (!value || value === "") return "neutral";
        return parseFloat(value) > 0 ? "up" : "down";
    };

    const formatPercentage = (value) => {
        if (!value || value === "") return "0.0";
        return parseFloat(value.replace('%', '')) || 0;
    };

    return [
        {
            label: "Cost",
            value: apiData.cost_kpi || "0",
            percentage: Math.abs(formatPercentage(apiData.cost_kpi_compare)).toFixed(1),
            direction: getDirection(apiData.cost_kpi_compare),
            extra: "",
            color: "#61D5A9",
        },
        {
            label: "Generated",
            value: apiData.generated_kpi || "0",
            percentage: Math.abs(formatPercentage(apiData.generated_kpi_compare)).toFixed(1),
            direction: getDirection(apiData.generated_kpi_compare),
            extra: "",
            color: "#DADADA",
        },
        {
            label: "Clicks",
            value: apiData.clicks_kpi || "0",
            percentage: Math.abs(formatPercentage(apiData.clicks_kpi_compare)).toFixed(1),
            direction: getDirection(apiData.clicks_kpi_compare),
            extra: "",
            color: "#CBCDFC",
        },
        {
            label: "CPC",
            value: apiData.cpc_kpi ? `${parseFloat(apiData.cpc_kpi).toFixed(2)} ${apiData.currency_symbol}` : `0 ${apiData.currency_symbol}`,
            percentage: Math.abs(formatPercentage(apiData.cpc_kpi_compare)).toFixed(1),
            direction: getDirection(apiData.cpc_kpi_compare),
            extra: "",
            color: "#FDBFB7",
        },
        {
            label: "Conversion Rate",
            value: apiData.conversion_kpi || "0",
            percentage: Math.abs(formatPercentage(apiData.conversion_kpi_compare)).toFixed(1),
            direction: getDirection(apiData.conversion_kpi_compare),
            extra: "",
            color: "#87B9FB",
        },
        {
            label: "ROAS",
            value: apiData.roas_kpi || "0",
            percentage: Math.abs(formatPercentage(apiData.roas_kpi_compare)).toFixed(1),
            direction: getDirection(apiData.roas_kpi_compare),
            extra: "",
            color: "#EBA9E3",
        },
    ];
};

/**
 * Fetches KPI reporting data from the API.
 *
 * @async
 * @function getKpiReporting
 * @param {Object} payload - The query parameters for the API request.
 * @returns {Promise<Object>} An object indicating success or failure, and data or error message.
 */
export const getKpiReporting = async (payload) => {
    const jwtToken = getUserToken(); 
    try {
        const response = await axios.post(
            `${APP_GLOBAL_REPORTING_API_ENDPOINT}/api/dashboard/kpi/`,
            {},  
            {
                params: payload,  
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return { success: true, data: parseMetrics(response.data) };
    } catch (error) {
        return {
            success: false,
            error: error.response?.data?.errorMessage || error.message || "Internal Server Error, please retry later"
        };
    }
};

