import axios from "axios";
import {getUserToken} from "../../../../../utils/functions/userToken";
import {APP_GOOGLE_PMAX_API_ENDPOINT} from "../../../../../constants/api_urls";

export const importGetCampaignAssetsListDetails = async (idHotel, idCampaign = null) => {
    const jwtToken = getUserToken();
    const url = `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/import/get-campaign-assets-list-details/`;
    const params = idCampaign
        ? { hotel_id: idHotel, campaign_id: idCampaign }
        : { hotel_id: idHotel };
    try {
        const response = await axios.get(
            url,
            {
                params: params,
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return { success: true, data: response.data.campaigns };
    } catch (error) {
        const errorMessage =
            error?.response?.data?.errorMessage || "Failed to fetch campaign details";
        return { success: false, error: errorMessage };
    }
};