// alertUtils.js

// Function to capitalize the first letter of a string
export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

// Function to format date into a readable format
export const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
};

// Function to concatenate email addresses with a comma and space
export const formatEmails = (emailArray) => {
    return emailArray.join(', ');
};

// Function to format alert data
export const formatAlertData = (data) => {
    return data.map(alert => ({
        ...alert,
        status: capitalizeFirstLetter(alert.status),
        created_date: formatDate(alert.created_date),
        updated_date: formatDate(alert.updated_date),
      //  alert_recipients: formatEmails(alert.alert_recipients),
    }));
};


export function sqlToJsonLogic(sqlWhereClause) {
    if (!sqlWhereClause) {
        return null;
    }

    // Tokenizer
    function tokenize(str) {
        const tokens = [];
        const regex = /\s*(<=|>=|!=|<>|=|<|>|\(|\)|AND|OR|[A-Za-z_][A-Za-z0-9_]*|-?\d+(\.\d+)?|'[^']*'|"[^"]*")\s*/gi;
        let match;
        while ((match = regex.exec(str)) !== null) {
            tokens.push(match[1]);
        }
        return tokens;
    }

    // Parser avec flattening pour AND et OR
    function parse(tokens) {
        let pos = 0;

        function parseExpression() {
            const terms = [parseTerm()];
            while (match('OR')) {
                pos++;
                terms.push(parseTerm());
            }
            // Aplatir les éventuels noeuds OR imbriqués
            const flatTerms = [];
            terms.forEach(term => {
                if (term && typeof term === 'object' && term.hasOwnProperty('or')) {
                    flatTerms.push(...term.or);
                } else {
                    flatTerms.push(term);
                }
            });
            return flatTerms.length === 1 ? flatTerms[0] : { or: flatTerms };
        }

        function parseTerm() {
            const factors = [parseFactor()];
            while (match('AND')) {
                pos++;
                factors.push(parseFactor());
            }
            // Aplatir les éventuels noeuds AND imbriqués
            const flatFactors = [];
            factors.forEach(factor => {
                if (factor && typeof factor === 'object' && factor.hasOwnProperty('and')) {
                    flatFactors.push(...factor.and);
                } else {
                    flatFactors.push(factor);
                }
            });
            return flatFactors.length === 1 ? flatFactors[0] : { and: flatFactors };
        }

        function parseFactor() {
            if (match('(')) {
                pos++;
                const node = parseExpression();
                if (match(')')) {
                    pos++;
                    return node;
                } else {
                    throw new Error("Parenthèse fermante attendue");
                }
            } else {
                return parseComparison();
            }
        }

        function parseComparison() {
            const left = parseValue();
            if (match('=', '!=', '<>', '<=', '>=', '<', '>')) {
                const operator = tokens[pos++];
                const right = parseValue();
                const opMap = {
                    '=': '==',
                    '!=': '!=',
                    '<>': '!=',
                    '<=': '<=',
                    '>=': '>=',
                    '<': '<',
                    '>': '>'
                };
                const op = opMap[operator];
                return { [op]: [{ var: left }, isNaN(right) ? right : parseFloat(right)] };
            } else {
                throw new Error("Opérateur de comparaison attendu");
            }
        }

        function parseValue() {
            const token = tokens[pos];
            if (/^-?\d+(\.\d+)?$/.test(token)) {
                pos++;
                return token;
            } else if (/^[A-Za-z_][A-Za-z0-9_]*$/.test(token)) {
                pos++;
                return token;
            } else if (/^'[^']*'$/.test(token) || /^"[^"]*"$/.test(token)) {
                pos++;
                return token.slice(1, -1);
            } else {
                throw new Error("Jeton inattendu : " + token);
            }
        }

        function match(...args) {
            if (pos < tokens.length) {
                const token = tokens[pos].toUpperCase();
                return args.some(arg => token === arg.toUpperCase());
            }
            return false;
        }

        const result = parseExpression();
        if (pos < tokens.length) {
            throw new Error("Jeton inattendu : " + tokens[pos]);
        }
        return result;
    }

    const tokens = tokenize(sqlWhereClause);
    return parse(tokens);
}

export function buildFieldMap(fields) {
    const map = {};

    for (let groupName in fields) {
        const group = fields[groupName];
        if (group.type === "!struct") {
            const subfields = group.subfields || {};
            for (let subfieldKey in subfields) {
                // Ex: subfieldKey = "totalSpentPercentEvo"
                // Nom complet = "Metrics Evolution.totalSpentPercentEvo"
                const fullKey = `${groupName}.${subfieldKey}`;
                map[subfieldKey] = fullKey;
            }
        } else {
            // Si jamais vous avez des champs top-level (sans !struct)
            map[groupName] = groupName;
        }
    }
    return map;
}

export function transformFieldNames(logic, fieldMap) {
    if (Array.isArray(logic)) {
        return logic.map(item => transformFieldNames(item, fieldMap));
    } else if (logic && typeof logic === 'object') {
        // Cas spécial: { "var": "xxx" }
        if (Object.keys(logic).length === 1 && logic.hasOwnProperty('var')) {
            const shortName = logic.var;
            // Si on a une correspondance dans le fieldMap, on remplace
            const fullPath = fieldMap[shortName];
            if (fullPath) {
                return { var: fullPath };
            } else {
                return logic; // pas de correspondance, on laisse tel quel
            }
        }

        // Sinon, on parcourt toutes les clés de l'objet
        const newObj = {};
        for (const k in logic) {
            newObj[k] = transformFieldNames(logic[k], fieldMap);
        }
        return newObj;
    }

    return logic; // nombre, string, null, etc.
}


