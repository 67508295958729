import React, { useState, useEffect } from "react";
import { Typography, Box, IconButton, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import { validateSiteLinkProperty } from "../../Utils/validators";

const SiteLinkExtensionSection = ({
  title,
  items,
  addItem,
  handleInputChange,
  handleRemove,
  onBlur,
  status = {},
}) => {
  const [isAdded, setIsAdded] = useState(false);
  const [siteLinkPropertyStatus, setSiteLinkProperty] = useState({});

  const handleSiteLinkPropertyBlur = async (property, index, value) => {
    const results = await validateSiteLinkProperty(property, {
      [property]: value,
    });

    setSiteLinkProperty((prev) => ({
      ...prev,
      [property + index]: results.success
        ? { success: true, error: null }
        : {
            success: false,
            error: results.error || `Invalid ${property} value`,
          },
    }));
  };

  console.log(siteLinkPropertyStatus, "Staustsuats");

  useEffect(() => {
    setIsAdded(items.length > 0);
  }, [items?.length]);

  const handleAddClick = () => {
    addItem();
  };

  return (
    <Grid item md={12} xs={12}>
      <Box className={title.toLowerCase().replace(/ /g, "-")} mt={4}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className="header-box"
        >
          <Typography variant="subtitle1">{title}</Typography>
          <Box display="flex" alignItems="center">
            {isAdded && (
              <IconButton
                onClick={addItem}
                color="primary"
                className="add-button"
              >
                <AddIcon />
              </IconButton>
            )}
          </Box>
        </Box>

        <Box className="content-box-one-line" mt={2}>
          {!isAdded ? (
            <Box
              display="flex"
              alignItems="center"
              gap={0}
              onClick={handleAddClick}
              style={{ cursor: "pointer", color: "#61D5A9" }}
            >
              <IconButton
                color="primary"
                className="add-button-text"
                style={{ fontSize: "10px" }}
              >
                <AddIcon />
              </IconButton>
              <Typography
                style={{
                  textTransform: "uppercase",
                  color: "#61D5A9",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                Add {title}
              </Typography>
            </Box>
          ) : (
            items.map((item, index) => (
              <Box key={item.id} mt={2}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: "12px",
                      fontFamily: "Inter",
                      fontWeight: 400,
                    }}
                  >
                    SITE LINK {index + 1}
                  </Typography>
                  <IconButton
                    onClick={() => handleRemove(item.id)}
                    color="primary"
                    className="remove-button"
                  >
                    <RemoveIcon />
                  </IconButton>
                </Box>

                <Grid container spacing={2} mt={1}>
                  <Grid item md={6} xs={12}>
                    <Box display="flex" alignItems="flex-start">
                      <Box display="flex" flexDirection="column" flexGrow={1}>
                        <CustomOutlinedInput
                          placeholder="Site Link Text"
                          className="no-border-focus"
                          fullWidth
                          value={item.sitelinkText}
                          onBlur={(e) =>
                            handleSiteLinkPropertyBlur(
                              "siteLinkText",
                              index,
                              e.target.value
                            )
                          }
                          onChange={(e) =>
                            handleInputChange(
                              item.id,
                              "sitelinkText",
                              e.target.value,
                              index
                            )
                          }
                          helperText={
                            siteLinkPropertyStatus?.["siteLinkText" + index]
                              ?.error || `Site Link Text`
                          }
                          error={
                            siteLinkPropertyStatus?.["siteLinkText" + index] &&
                            siteLinkPropertyStatus?.["siteLinkText" + index]
                              ?.success === false
                          }
                          success={
                            siteLinkPropertyStatus?.["siteLinkText" + index] &&
                            siteLinkPropertyStatus?.["siteLinkText" + index]
                              ?.success === true
                          }
                        />
                      </Box>
                      <Box width="28px" height="28px" />
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Box display="flex" alignItems="flex-start">
                      <Box display="flex" flexDirection="column" flexGrow={1}>
                        <CustomOutlinedInput
                          placeholder="Description 1"
                          className="no-border-focus"
                          fullWidth
                          value={item.description1}
                          onChange={(e) =>
                            handleInputChange(
                              item.id,
                              "description1",
                              e.target.value,
                              index
                            )
                          }
                          onBlur={(e) =>
                            handleSiteLinkPropertyBlur(
                              "siteLinkDescription1",
                              index,
                              e.target.value
                            )
                          }
                          helperText={
                            siteLinkPropertyStatus?.[
                              "siteLinkDescription1" + index
                            ]?.error || `Description 1`
                          }
                          error={
                            siteLinkPropertyStatus?.[
                              "siteLinkDescription1" + index
                            ] &&
                            siteLinkPropertyStatus?.[
                              "siteLinkDescription1" + index
                            ]?.success === false
                          }
                          success={
                            siteLinkPropertyStatus?.[
                              "siteLinkDescription1" + index
                            ] &&
                            siteLinkPropertyStatus?.[
                              "siteLinkDescription1" + index
                            ]?.success === true
                          }
                        />
                      </Box>
                      <Box width="28px" height="28px" />
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Box display="flex" alignItems="flex-start">
                      <Box display="flex" flexDirection="column" flexGrow={1}>
                        <CustomOutlinedInput
                          placeholder="Description 2"
                          className="no-border-focus"
                          fullWidth
                          value={item.description2}
                          onBlur={(e) =>
                            handleSiteLinkPropertyBlur(
                              "siteLinkDescription2",
                              index,
                              e.target.value
                            )
                          }
                          onChange={(e) =>
                            handleInputChange(
                              item.id,
                              "description2",
                              e.target.value,
                              index
                            )
                          }
                          helperText={
                            siteLinkPropertyStatus?.[
                              "siteLinkDescription2" + index
                            ]?.error || `Description 2`
                          }
                          error={
                            siteLinkPropertyStatus?.[
                              "siteLinkDescription2" + index
                            ] &&
                            siteLinkPropertyStatus?.[
                              "siteLinkDescription2" + index
                            ]?.success === false
                          }
                          success={
                            siteLinkPropertyStatus?.[
                              "siteLinkDescription2" + index
                            ] &&
                            siteLinkPropertyStatus?.[
                              "siteLinkDescription2" + index
                            ]?.success === true
                          }
                        />
                      </Box>
                      <Box width="28px" height="28px" />
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Box display="flex" alignItems="flex-start">
                      <Box display="flex" flexDirection="column" flexGrow={1}>
                        <CustomOutlinedInput
                          placeholder="Final URL"
                          className="no-border-focus"
                          fullWidth
                          value={item.finalURL}
                          onChange={(e) =>
                            handleInputChange(
                              item.id,
                              "finalURL",
                              e.target.value,
                              index
                            )
                          }
                          onBlur={(e) =>
                            handleSiteLinkPropertyBlur(
                              "finalURL",
                              index,
                              e.target.value
                            )
                          }
                          helperText={
                            siteLinkPropertyStatus?.["finalURL" + index]
                              ?.error || `Final URL`
                          }
                          error={
                            siteLinkPropertyStatus?.["finalURL" + index] &&
                            siteLinkPropertyStatus?.["finalURL" + index]
                              ?.success === false
                          }
                          success={
                            siteLinkPropertyStatus?.["finalURL" + index] &&
                            siteLinkPropertyStatus?.["finalURL" + index]
                              ?.success === true
                          }
                        />
                      </Box>
                      <Box width="28px" height="28px" />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ))
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default SiteLinkExtensionSection;
