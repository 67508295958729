export const COLUMNS_HEADER = [
  { id: 'id',  label:'ID' },
  { id: 'name', label:'Name' },
  { id: 'action',  label:'Action' },
  { id: 'report_type',  label:'Report Type' },
  { id: 'meta_search',  label:'Metasearch' },
  { id: 'date_created',  label:'Created Date' },
  { id: 'delivery_frequency',  label:'Frequency' },
  { id: 'status',  label:'Status' },
  { id: 'owner_username', label:'User' }
];


export const TOOLTIP_TEXT = [
  { id: 'id',  label:'ID' },
  { id: 'name', label:'Name' },
  { id: 'action',  label:'Action' },
  { id: 'report_type',  label:'Report Type' },
  { id: 'meta_search',  label:'Metasearch' },
  { id: 'date_created',  label:'Created Date' },
  { id: 'delivery_frequency',  label:'Frequency' },
  { id: 'status',  label:'Status' },
  { id: 'owner_username', label:'User' }
  ];