import {APP_GLOBAL_REPORTING_API_ENDPOINT} from "../../../../constants/api_urls";
import axios from "axios";
import {getUserToken} from "../../../../utils/functions/userToken";

/**
 * Fetches metasearch reporting data from the API.
 *
 * @async
 * @function getMetasearchReporting
 * @param {Object} payload - The query parameters to be sent with the API request.
 * @param {string} payload.startDate - The start date for the reporting data (e.g., "YYYY-MM-DD").
 * @param {string} payload.endDate - The end date for the reporting data (e.g., "YYYY-MM-DD").
 * @param {string} [payload.additionalParams] - Optional additional query parameters.
 * @returns {Promise<Object>} - A promise that resolves to an object containing:
 *   - {boolean} success - Whether the request was successful.
 *   - {Object} [data] - The response data from the API if the request was successful.
 *   - {Object} [error] - The error object if the request failed.
 * @throws {Error} - If the request fails for any reason, the error will be caught and returned.
 */
export const getMetasearchReporting = async (payload) => {
    const jwtToken = getUserToken();
    try {
        const response = await axios.post(
            `${APP_GLOBAL_REPORTING_API_ENDPOINT}/api/dashboard/reporting/`,
            {},
            {
                params: payload,
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return { success: true, data: response.data };
    } catch (error) {
        return {
            success: false,
            error: error.response?.data?.errorMessage || error.message || "Internal Server Error, please retry later"
        };
    }
};
