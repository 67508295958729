import React, {useState} from "react";
import {Button} from "@mui/material";
import CampaignImportDialog from "./CampaignImportDialog";
import ConfirmImportDialogCampaign from "./ConfirmImportDialogCampaign";

const ImportCampaignDialogHandler = ({
    campaignList,
    setLandscape,
    setLandscapeLogo,
    setLogo,
    setPortrait,
    setSquare
}) => {
    const [importDialogOpen, setImportDialogOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [selectedCampaignName, setSelectedCampaignName] = useState("");
    const [selectedCampaign, setSelectedCampaign] = useState(null);

    const handleOpenImportDialog = () => {
        setImportDialogOpen(true);
    };

    const handleCloseImportDialog = () => {
        setImportDialogOpen(false);
    };

    const handleConfirmImport = (campaignId) => {
        const selectedCampaign = campaignList.find((c) => c.campaign_id === campaignId);
        setSelectedCampaign(selectedCampaign);
        setSelectedCampaignName(
            selectedCampaign.campaign_settings?.campaign_name ?? `Campaign with ID : ${selectedCampaign.campaign_id}`
        );
        setImportDialogOpen(false);
        setConfirmDialogOpen(true);
    };

    const handleFinalImport = () => {
        setLandscape(selectedCampaign.campaign_ads_creative.marketing_image.map(item => item.assetContent));
        setLandscapeLogo(selectedCampaign.campaign_ads_creative.landscape_logo_image.map(item => item.assetContent));
        setLogo(selectedCampaign.campaign_ads_creative.logo_image.map(item => item.assetContent));
        setPortrait(selectedCampaign.campaign_ads_creative.portrait_marketing_image.map(item => item.assetContent));
        setSquare(selectedCampaign.campaign_ads_creative.square_marketing_image.map(item => item.assetContent));
        setConfirmDialogOpen(false);
    };

    return (
        <>
            <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={handleOpenImportDialog}
                sx={{
                    marginLeft: 'auto',
                    marginRight: '15px',
                    marginBottom: '5px'
                }}
            >
                Import images from campaign
            </Button>

            <CampaignImportDialog
                open={importDialogOpen}
                onClose={handleCloseImportDialog}
                campaignList={campaignList}
                onConfirm={handleConfirmImport}
            />

            <ConfirmImportDialogCampaign
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                onConfirm={handleFinalImport}
                campaignName={selectedCampaignName}
            />
        </>
    );
};

export default ImportCampaignDialogHandler;