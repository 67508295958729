import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  campaign: [],
  segment_list: [],
  keywordsReportingSegmentList: [],
  searchTermReportingSegmentList: [],
  selected_segments: null,
  keywords_selected_segments: null,
  searchterm_selected_segments: null,
  sharedBudgetEditRow: null
};
export const searchAdsSlice = createSlice({
  name: "searchAdsSlice",
  initialState: initialState,
  reducers: {
    updateCampaign: (state, action) => {
      state.campaign = action.payload;
    },
    updateSegmentList: (state, action) => {
      state.segment_list = action.payload;
    },
    updateKeywordsReportingSegmentList: (state,action) => {
      state.keywordsReportingSegmentList = action.payload
    },
    updateSearchTermReportingSegmentList: (state,action) => {
      state.searchTermReportingSegmentList = action.payload
    },
    updateSelectedSegments: (state,action) => {
      state.selected_segments = action.payload;
    },
    updateKeywordsReportingSelectedSegments: (state,action) => {
      state.keywords_selected_segments = action.payload
    },
    updateSearchTermReportingSelectedSegments: (state,action) => {
      state.searchterm_selected_segments = action.payload
    },
    setSharedBudgetEditRow: (state,action) => {
      state.sharedBudgetEditRow = action.payload
    },
    resetSearchAds: () => initialState
  },
});

export const {
  updateCampaign,
  updateSegmentList,
  updateKeywordsReportingSegmentList,
  updateSearchTermReportingSegmentList,
  updateSelectedSegments,
  updateKeywordsReportingSelectedSegments,
  updateSearchTermReportingSelectedSegments,
  resetSearchAds,
  setSharedBudgetEditRow,
} = searchAdsSlice.actions;
export default searchAdsSlice.reducer;
