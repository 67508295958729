import React, {useEffect, useMemo, useRef, useState} from "react";
import {Box, Grid, Chip} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import DefaultLayout from "layout/defaultLayout";
import PageTitles from "../../components/PageTitles";
import {DatePicker} from "../../components/DatePicker";
import MetricsComponent from "../../features/new-homepage/MetricsComponent";
import ReportingMetricsTable from "../../features/new-homepage/ReportingMetricsTable";
import ChartComponent from "../../features/new-homepage/ChartComponent";
import DeviceComponent from "../../features/new-homepage/DeviceComponent";
import TopHotelsComponent from "../../features/new-homepage/TopHotelsComponent";
import TopMarketsComponent from "../../features/new-homepage/TopMarketsComponent";
import TableContainer from "@mui/material/TableContainer";

import {useFetchMetricsData} from "./utils/hooks/useFetchMetricsData";

import "./new-homepage.scss";
import HotelsFilterMenu from "components/hotelFilterMenu";
import { updateGroupId, updateHotelId, updateLevel } from "store/slices/account/accountSlice";

const NewHomepage = () => {
  const { idaccount } = useParams();
  const dispatch = useDispatch()
  const { calender } = useSelector((state) => state);
  const hotelButtonRef = useRef(null);
  const {hotel_id = [], group_id = [], level , hotelList = [], groupList = [], showGroups} = useSelector(state => state?.account)
  const [openHotelModal, setOpenHotelModal] = useState(false);
  const [selectedHotelOrGroupType, setSelectedHotelOrGroupType] =
    useState("hotels");

  const [deviceIdChannel, setDeviceIdChannel] = useState("GHA_META");
  const [topHotelsIdChannel, setTopHotelsIdChannel] = useState("GHA_META");
  const [topMarketsIdChannel, setTopMarketsIdChannel] = useState("GHA_META");
  const [isChartCollapsed, setIsChartCollapsed] = useState(false);

  useEffect(() => {
    if(group_id?.length){
      dispatch(updateLevel("GROUP"))
    } else {
      dispatch(updateLevel("HOTEL"))
    }
  },[group_id,dispatch])

  const handleDeviceChange = (event) => {
    const newChannel = event.target.value;
    setDeviceIdChannel(newChannel);
    handleDropdownChange(event, "device");
  };

  const handleTopHotelsChange = (event) => {
    const newChannel = event.target.value;
    setTopHotelsIdChannel(newChannel);
    handleDropdownChange(event, "topHotels");
  };

  const handleTopMarketsChange = (event) => {
    const newChannel = event.target.value;
    setTopMarketsIdChannel(newChannel);
    handleDropdownChange(event, "topMarkets");
  };

  const hotelIdsOrGroupIds = useMemo(() => {
    if(level === "HOTEL"){
      return Array.from(hotel_id || []).filter(id => hotelList.map((h) => h?.["hotel_id"])?.includes(id) && String(hotelList?.[0]?.account_id) === String(idaccount))
    } else {
      return Array.from(group_id || []).filter(id => groupList.map((h) => h?.["id"])?.includes(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[level, JSON.stringify(hotel_id),JSON.stringify(group_id), JSON.stringify(hotelList), JSON.stringify(groupList), idaccount])

  const {
    metrics,
    reportingMetrics,
    chartsMetrics,
    deviceMetrics,
    topHotelsMetrics,
    topMarketsMetrics,
    errorsMessage,
    handleDropdownChange,
    fetchData,
  } = useFetchMetricsData(idaccount, calender, hotelIdsOrGroupIds, level);

  useEffect(() => {
    if (calender?.currentDateRange?.startDate && calender?.currentDateRange?.endDate) {
      fetchData();
    }
  }, [
    calender.currentDateRange.startDate,
    calender.currentDateRange.endDate,
    calender.comparitionDateRange.startDate,
    calender.comparitionDateRange.endDate,
    idaccount,
    fetchData,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(hotelIdsOrGroupIds)
  ]);

  return (
    <DefaultLayout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "18px",
          marginLeft: "2px",
        }}
      >
        <PageTitles pageTitle="Digital Marketing Platform" />
        <DatePicker />
      </Box>

      <Box paddingLeft={2} sx={{
        display: "flex",
        alignItems: "center",
        color: "#000000",
        fontWeight: "400",
        marginTop: "4px",
      }}>
        <div
          onClick={() => {
            setOpenHotelModal(true);
          }}
          ref={hotelButtonRef}
        >
          <Chip
            label={
              level === "HOTEL"
                ? hotel_id.length === 0
                  ? "Hotels"
                  : hotel_id.length === hotelList.length
                  ? "All Hotels"
                  : hotel_id.length === 1
                  ? hotelList?.find((hotel) => hotel.hotel_id === hotel_id?.[0])
                      ?.hotel_name
                  : `${hotel_id.length} ${"hotels"} selected`
                : group_id.length === 0
                ? "Groups"
                : group_id.length === groupList.length
                ? "All Groups"
                : group_id.length === 1
                ? groupList?.find((group) => group.id === group_id?.[0])?.name
                : `${group_id.length} ${"groups"} selected`
            }
            sx={{
              backgroundColor: "inherit",
              fontFamily: "inter",
              fontSize: "12px",
              fontWeight: "400",
              marginRight: "4px",
              height: "26px",
              minWidth: "120px",
              border: "1px solid #BAB7B7",
              ":hover": {
                cursor: "pointer",
                border: "1px solid #000000",
              },
            }}
          />
        </div>
        {openHotelModal && (
          <HotelsFilterMenu
            allHotels={hotelList?.map((ho) =>
              hotel_id.find((hf) => hf === ho.hotel_id)
                ? { ...ho, isSelected: true }
                : { ...ho, isSelected: false }
            )}
            showGroups={showGroups}
            anchorEl={hotelButtonRef.current}
            open={openHotelModal}
            allGroups={groupList?.map((ho) =>
              group_id.find((hf) => hf === ho.id)
                ? { ...ho, isSelected: true }
                : { ...ho, isSelected: false }
            )}
            isSelectAllRequired
            onApply={(selected) => {
              dispatch(
                updateLevel(
                  selectedHotelOrGroupType === "hotels" ? "HOTEL" : "GROUP"
                )
              );
              if (selectedHotelOrGroupType === "hotels") {
                dispatch(
                  updateHotelId(selected.map((item) => item?.["hotel_id"]))
                );
                dispatch(updateGroupId([]));
              } else {
                dispatch(updateGroupId(selected.map((item) => item?.["id"])));
                dispatch(updateHotelId([]));
              }
            }}
            onClose={() => {
              setOpenHotelModal(false);
            }}
            setSelectedHotelOrGroupType={(type) => {
              setSelectedHotelOrGroupType(type);
            }}
            selectedHotelOrGroupType={selectedHotelOrGroupType}
          />
        )}
      </Box>

      <Box
        sx={{ backgroundColor: "#FAFAFA", pt: "14px", paddingInline: "18px" }}
      >
        <MetricsComponent
          metrics={metrics}
          calender={calender}
          errorMessage={errorsMessage.kpi}
        />

        <Grid container spacing="14px" sx={{ pt: "14px" }}>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <ReportingMetricsTable
              metrics={reportingMetrics}
              errorMessage={errorsMessage.reporting}
            />
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
              {!isChartCollapsed && (
                <ChartComponent
                  menuOptions={chartsMetrics.metrics}
                  comparitionData={chartsMetrics.compareChartData}
                  rawChartData={chartsMetrics.chartData}
                  axisData={chartsMetrics.chartAxisDate}
                  errorMessage={errorsMessage.charts}
                />
              )}
              <TableContainer
                setIsChartCollapsed={setIsChartCollapsed}
                isChartCollapsed={isChartCollapsed}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing="14px" sx={{ pb: 5, paddingTop: "14px" }}>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <DeviceComponent
              selectedOption={deviceIdChannel}
              handleDropdownChange={handleDeviceChange}
              deviceMetrics={deviceMetrics}
              errorMessage={errorsMessage.device}
            />
            <TopHotelsComponent
              selectedOption={topHotelsIdChannel}
              handleDropdownChange={handleTopHotelsChange}
              topHotelsMetrics={topHotelsMetrics}
              errorMessage={errorsMessage.topHotels}
            />
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <TopMarketsComponent
              selectedOption={topMarketsIdChannel}
              handleDropdownChange={handleTopMarketsChange}
              topMarketsMetrics={topMarketsMetrics}
              errorMessage={errorsMessage.topMarkets}
            />
          </Grid>
        </Grid>
      </Box>
    </DefaultLayout>
  );
};

export default NewHomepage;